import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { AuthProvider } from "./context/AuthContext.jsx";
// import { UserProvider } from "./context/UserContext.jsx";

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <AuthProvider>
      {/* <UserProvider> */}
      <App />
      {/* </UserProvider> */}
    </AuthProvider>
  </React.StrictMode>
);
