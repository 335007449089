import React, { useState, useEffect } from "react";
import axios from "axios";
import { useAuth } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { BaseMenuItem, Typeahead } from "react-bootstrap-typeahead";
import { BASE_URL } from "../../utils/routeNames";
import "react-bootstrap-typeahead/css/Typeahead.css";
import imageCompression from "browser-image-compression";
import toast from "react-hot-toast";
import { Spinner } from "reactstrap";
const NewProduct = () => {
  const navigate = useNavigate();
  const [auth] = useAuth();
  const [formData, setFormData] = useState({
    // sku: "",
    name: "",
    imageUrl: "",
    additionalPhotos: [],
    description: "-",
    purchasePrice: "",
    mrp: "",
    salePrice: "",
    stock: "1",
    category: "",
    currency: "₹",
    isAvailable: true,
    tags: "",
    keywords: "",
    mobile: [], // New field for selected variants
    commissionType: "Percentage",
    commission: 15,
    isWarrantyApplicable: false,
    warrantyInMonth: 0,
    productManager: auth?.user._id,
    createdBy: auth?.user._id,
    isPublished: true,
  });
  const [errors, setErrors] = useState({
    // sku: "",
    name: "",
    description: "",
    purchasePrice: "",
    mrp: "",
    mobile: [],
    salePrice: "",
    stock: "",
    category: "",
    imageUrl: "",
    commission: "", // Added commission error
    isWarrantyApplicable: false,
    warrantyInMonth: "",
  });

  const [file, setFile] = useState(null);
  const [preview, setPreview] = useState(null);
  const [message, setMessage] = useState("");
  const [categories, setCategories] = useState([]);
  // const [variants, setVariants] = useState([]);
  const [models, setModels] = useState([]);

  const validateForm = () => {
    const newErrors = {};

    // // SKU validation
    // if (!formData.sku) newErrors.sku = "SKU is required.";

    // Product Name validation
    if (!formData.name) newErrors.name = "Product name is required.";

    // Description validation
    // if (!formData.description)
    //   newErrors.description = "Description is required.";

    // mobiles validation
    if (!formData.mobile) newErrors.mobile = "Mobile is required.";

    // Purchase Price validation
    if (!formData.purchasePrice || formData.purchasePrice <= 0)
      newErrors.purchasePrice = "Purchase price must be a positive number.";

    // MRP validation
    if (!formData.mrp || formData.mrp <= 0)
      newErrors.mrp = "MRP must be a positive number.";

    // Sale Price validation
    if (!formData.salePrice || formData.salePrice <= 0)
      newErrors.salePrice = "Sale price must be a positive number.";

    // Stock validation
    if (!formData.stock || formData.stock <= 0)
      newErrors.stock = "Stock must be a positive number.";

    // Category validation
    if (!formData.category) newErrors.category = "Please select a category.";

    // Image validation
    if (!preview) newErrors.imageUrl = "Product image is required.";

    if (
      formData.isWarrantyApplicable === true && // Warranty is applicable
      (!formData.warrantyInMonth || parseInt(formData.warrantyInMonth, 10) <= 0) // Convert to number and validate
    ) {
      newErrors.warrantyInMonth =
        "Please enter a valid warranty period (in months).";
    }

    // if (additionalPhotos.length === 0)
    //   newErrors.additionalPhotos = "Additional Photos are required.";

    // Commission validation
    // if (!formData.commission || formData.commission <= 0)
    //   newErrors.commission = "Commission must be a positive number.";

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };
  const [additionalPhotos, setAdditionalPhotos] = useState([]);
  const [uploadedPhotos, setUploadedPhotos] = useState([]);

  const handleMultipleFileChange = async (event) => {
    const files = Array.from(event.target.files);

    const compressedFiles = await Promise.all(
      files.map(async (file) => {
        const options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 1920,
          useWebWorker: true,
        };
        try {
          const compressedImage = await imageCompression(file, options);
          const compressedFile = new File([compressedImage], file.name, {
            type: compressedImage.type,
          });
          return {
            file: compressedFile,
            preview: URL.createObjectURL(compressedFile),
          };
        } catch (error) {
          console.error("Error compressing file:", error);
          return null;
        }
      })
    );

    setAdditionalPhotos((prevPhotos) => [
      ...prevPhotos,
      ...compressedFiles.filter(Boolean),
    ]);
  };

  const handleUploadAdditionalPhotos = async () => {
    const additionalPhotoData = new FormData();
    additionalPhotos.forEach((attachment) => {
      additionalPhotoData.append("docs", attachment.file);
    });

    try {
      const response = await axios.post(
        `${BASE_URL}/upload/docs`,
        additionalPhotoData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${auth.token}`,
          },
        }
      );

      // Map the uploaded files into an array of URLs
      const uploaded = response.data.files.map((file) => file.url);

      // Update the uploaded photos state
      // setUploadedPhotos((prev) => [...prev, ...uploaded]);

      // Append the uploaded URLs to additionalPhotos (not wrapped in arrays)
      formData.additionalPhotos.push(...uploaded);
      console.log("formData.additionalPhotos", formData.additionalPhotos);

      // const productData = {
      //   ...formData,
      //   additionalPhotos: uploaded,
      // };
      setAdditionalPhotos([]);
    } catch (error) {
      console.error("Error uploading files:", error);
    }
  };

  const removePhoto = (index) => {
    setAdditionalPhotos((prevPhotos) =>
      prevPhotos.filter((_, idx) => idx !== index)
    );
  };
  // Fetch categories
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const res = await axios.get(`${BASE_URL}/category/allCategory`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${auth?.token}`,
          },
        });
        const data = res.data.categories || [];
        const formattedCategories = data.map((category) => ({
          id: category._id,
          label: category.category_name,
        }));
        setCategories(formattedCategories);
        console.log("formattedCategories :", formattedCategories);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    const fetchModelsORVariants = async () => {
      try {
        //fetching only models for start later u need to fetch variants as well
        const res = await axios.get(`${BASE_URL}/mobile/allModels`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${auth?.token}`,
          },
        });

        // Extract models array or handle fallback
        const data = Array.isArray(res.data) ? res.data : res.data.models || [];

        if (!Array.isArray(data)) {
          throw new Error("Expected an array but received something else.");
        }

        const formattedModels = data.map((model) => ({
          id: model._id,
          label: `${model.brandId.name}, ${model.name}`,
        }));
        setModels(formattedModels);
        console.log("Models", res.data);
      } catch (error) {
        console.error("Error fetching mobile Models:", error);
      }
    };

    fetchCategories();
    fetchModelsORVariants();
  }, [auth]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSelectChange = (selectedOption) => {
    setFormData((prev) => ({
      ...prev,
      category: selectedOption.length ? selectedOption[0].id : null,
    }));
    console.log("selectedOption :", formData.category);
  };
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleVariantChange = (selectedVariants) => {
    setFormData((prev) => ({
      ...prev,
      mobile: selectedVariants.map((variant) => ({
        modelId: variant.id,
        variantId: null,
      })),
    }));
  };
  // Image compression options
  const options = {
    maxSizeMB: 1, // Maximum file size in MB
    maxWidthOrHeight: 800, // Max width or height
    useWebWorker: true, // Use web worker for faster compression
  };

  const [creatingProduct, setCreatingProduct] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    try {
      setCreatingProduct(true);
      const uploadData = new FormData();
      const compressedImage = await imageCompression(file, options);
      // Convert Blob to File
      const compressedFile = new File(
        [compressedImage], // Blob content
        file.name, // Use the original file name
        { type: compressedImage.type } // MIME type
      );
      uploadData.append("doc", compressedFile);
      const uploadResponse = await axios.post(
        `${BASE_URL}/upload/doc`,
        uploadData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${auth?.token}`,
          },
        }
      );

      const imageUrl = uploadResponse.data.imageUrl;
      const salePrice = parseFloat(formData.salePrice);
      const commission = parseFloat(formData.commission);

      let salePriceWithCommission = salePrice + (salePrice * commission) / 100;
      const productData = {
        ...formData,
        imageUrl,
        mobile: formData.mobile,
        salePriceWithCommission: salePriceWithCommission,
      };

      await handleUploadAdditionalPhotos();
      console.log("productData", productData);

      const res = await axios.post(
        `${BASE_URL}/product/newProduct`,
        productData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${auth?.token}`,
          },
        }
      );
      console.log("response", res.data);

      if (res.status === 201) {
        addProductForVendor(res.data.product);
      }
    } catch (error) {
      toast.error("Error creating product:", error);
      setMessage(`Failed to create product.${error}`);
      setCreatingProduct(false);
    }
  };

  const addProductForVendor = async (product) => {
    // Calculate salePriceWithCommission
    const salePrice = parseFloat(product.salePrice);
    const commission = parseFloat(product.commission);
    let salePriceWithCommission = salePrice + (salePrice * commission) / 100;

    const vendorProductData = {
      vendorId: "675a0e7ac0a4d4299f172278",
      product: {
        productId: product._id,
        sku: product.sku,
        name: product.name,
        description: product.description,
        mobile: product.mobile,
        purchasePrice: product.purchasePrice,
        mrp: product.mrp,
        salePrice: product.salePrice,
        stock: product.stock,
        category: product.category,
        commissionType: product.commissionType,
        commission: product.commission,
        imageUrl: product.imageUrl,
        additionalPhotos: product.additionalPhotos,
        currency: product.currency,
        isAvailable: product.isAvailable,
        isWarrantyApplicable: product.isAvailable,
        warrantyInMonth: product.warrantyInMonth,
      },

      salePriceWithCommission: salePriceWithCommission,
      purchasePrice: product.purchasePrice,
      mrp: product.mrp,
      salePrice: product.salePrice,
    };

    try {
      const res = await axios.post(
        `${BASE_URL}/vendorProducts/createVendorProduct`,
        vendorProductData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${auth?.token}`,
          },
        }
      );
      if (res.status === 201) {
        setCreatingProduct(false);
        toast.success("Product uploaded and assigned to vendor");
        navigate(-1);
      }
    } catch (error) {
      console.error("Error creating product:", error);
      setMessage("Failed to create product.");
    }
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      const reader = new FileReader();
      reader.onload = () => setPreview(reader.result);
      reader.readAsDataURL(selectedFile);
    }
  };

  const [showDuration, setShowDuration] = useState(false);
  useEffect(() => {
    setShowDuration(formData.isWarrantyApplicable);
  }, [formData.isWarrantyApplicable]);

  const handleWarranty = (value) => {
    setFormData((prev) => ({
      ...prev,
      isWarrantyApplicable: value === "true",
    }));
  };
  const handleWarrantyDuration = (value) => {
    setFormData((prev) => ({
      ...prev,
      warrantyInMonth: parseInt(value, 10),
    }));
  };
  return (
    <>
      {creatingProduct ? (
        <div className="d-flex justify-content-center">
          {/* Reactstrap Spinner */}
          <Spinner style={{ width: "3rem", height: "3rem" }} color="dark" />
        </div>
      ) : (
        <div className="content-wrapper">
          <section className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1 className="text-dark">Add Product</h1>
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item">
                      <a href="#">Home</a>
                    </li>
                    <li className="breadcrumb-item active">Add Product</li>
                  </ol>
                </div>
              </div>
            </div>
          </section>

          <section className="content">
            {message && <p>{message}</p>}
            <form
              id="productForm"
              onSubmit={handleSubmit}
              className="card card-dark"
            >
              <div className="card-header">
                <h3 className="card-title">Add Product</h3>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="mobile">Mobile </label>
                      <Typeahead
                        id="mobile-variants-typeahead"
                        options={models}
                        onChange={(selected) => {
                          handleVariantChange(selected); // Call your existing variant change handler
                          setIsDropdownOpen(false); // Close the dropdown after selection
                        }}
                        placeholder="Select mobile"
                        selected={models.find(
                          (model) => formData.mobile?.modelId === model.id
                        )} // Select only the model that matches formData.mobile.modelId
                        onFocus={() => setIsDropdownOpen(true)}
                        onBlur={() => setIsDropdownOpen(false)} // Close dropdown on blur
                        open={isDropdownOpen} // Control dropdown visibility
                        clearButton
                      />

                      {/* <Typeahead
                        id="mobile-variants-typeahead"
                        options={models}
                        onChange={handleVariantChange}
                        placeholder="Select mobile "
                        multiple
                        selected={models.filter((model) =>
                          formData.mobile.some(
                            (mobile) => mobile.modelId === model.id
                          )
                        )}
                        clearButton
                      /> */}
                      {errors.mobile && (
                        <small className="text-danger">{errors.mobile}</small>
                      )}
                    </div>
                    <div className="form-group">
                      <label htmlFor="category">Category</label>
                      <Typeahead
                        id="category-typeahead"
                        options={categories}
                        onChange={handleSelectChange}
                        placeholder="Select Category"
                        selected={categories.filter(
                          (category) => category.id === formData.category
                        )}
                      />
                      {errors.category && (
                        <small className="text-danger">{errors.category}</small>
                      )}
                    </div>
                    <div className="card card-dark card-outline">
                      <div className="card-body">
                        <div className="form-group">
                          <label htmlFor="imageUrl">Product Image</label>
                          <div className="custom-file">
                            <input
                              type="file"
                              className="custom-file-input"
                              id="imageUrl"
                              onChange={handleFileChange}
                            />
                            <label
                              className="custom-file-label"
                              htmlFor="imageUrl"
                            >
                              Choose Product Image
                            </label>
                          </div>
                          {preview && (
                            <div className="mt-3">
                              <img
                                src={preview}
                                alt="Preview"
                                className="img-thumbnail"
                                style={{ width: "150px" }}
                              />
                            </div>
                          )}
                          {errors.imageUrl && (
                            <small className="text-danger d-block mt-2">
                              {errors.imageUrl}
                            </small>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="card card-dark card-outline">
                      <div className="card-header">
                        <h3 className="card-title">Additional Photos</h3>
                      </div>
                      <div className="card-body">
                        <div className="form-group">
                          <label htmlFor="photoUpload">Select Photos</label>

                          <div className="custom-file">
                            <input
                              type="file"
                              id="photoUpload"
                              className="custom-file-input"
                              multiple
                              onChange={handleMultipleFileChange}
                              accept="image/*"
                            />
                            <label
                              className="custom-file-label"
                              htmlFor="imageUrl"
                            >
                              Choose Additional Photos
                            </label>
                            {errors.additionalPhotos && (
                              <small className="text-danger d-block mt-2">
                                {errors.additionalPhotos}
                              </small>
                            )}
                          </div>
                        </div>

                        <div className="form-group">
                          <h5>Selected Photos</h5>
                          {additionalPhotos.length > 0 ? (
                            <div className="row">
                              {additionalPhotos.map((photo, index) => (
                                <div key={index} className="col-md-2">
                                  <div className="card">
                                    <img
                                      src={photo.preview}
                                      alt="Selected"
                                      className="card-img-top"
                                      style={{
                                        height: "100px",
                                        objectFit: "cover",
                                      }}
                                    />
                                    <div className="card-body text-center">
                                      <button
                                        className="btn btn-danger btn-sm"
                                        onClick={() => removePhoto(index)}
                                      >
                                        Remove
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          ) : (
                            <p>No photos selected.</p>
                          )}
                        </div>

                        {/* <button
                            className="btn btn-success"
                            onClick={handleUploadAdditionalPhotos}
                            disabled={!additionalPhotos.length}
                          >
                            Upload Photos
                          </button> */}
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="name">Product Name</label>
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        placeholder="Enter Product Name"
                      />
                      {errors.name && (
                        <small className="text-danger">{errors.name}</small>
                      )}
                    </div>
                    <div className="form-group">
                      <label htmlFor="description">Product Description</label>
                      <textarea
                        className="form-control"
                        id="description"
                        name="description"
                        value={formData.description}
                        onChange={handleChange}
                        placeholder="Enter Product Description"
                      ></textarea>
                      {errors.description && (
                        <small className="text-danger">
                          {errors.description}
                        </small>
                      )}
                    </div>

                    <div className="form-group">
                      <label>Does this provide warranty?</label>
                      <div className="d-flex align-items-center ">
                        <div className="form-check mr-3">
                          <input
                            type="radio"
                            id="warrantyYes"
                            name="isWarrantyApplicable"
                            value="true"
                            checked={formData.isWarrantyApplicable === true} // Compare boolean value
                            onChange={(e) => handleWarranty(e.target.value)}
                            className="form-check-input"
                          />
                          <label
                            htmlFor="warrantyYes"
                            className="form-check-label"
                          >
                            Yes
                          </label>
                        </div>

                        <div className="form-check">
                          <input
                            type="radio"
                            id="warrantyNo"
                            name="isWarrantyApplicable"
                            value="false"
                            checked={formData.isWarrantyApplicable === false} // Compare boolean value
                            onChange={(e) => handleWarranty(e.target.value)}
                            className="form-check-input"
                          />
                          <label
                            htmlFor="warrantyNo"
                            className="form-check-label"
                          >
                            No
                          </label>
                        </div>
                      </div>
                    </div>

                    {showDuration && (
                      <div className="form-group">
                        <label htmlFor="duration">
                          Warranty(In no of Months)
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          id="duration"
                          name="duration"
                          value={formData.duration}
                          onChange={(e) =>
                            handleWarrantyDuration(e.target.value)
                          }
                          min="1"
                          placeholder="Enter Warranty in  no of months"
                        />
                        {errors.warrantyInMonth && (
                          <small className="text-danger">
                            {errors.warrantyInMonth}
                          </small>
                        )}
                      </div>
                    )}
                    <div className="card card-dark card-outline">
                      <div className="card-body">
                        <div className="form-group">
                          <label htmlFor="purchasePrice">Purchase Price</label>
                          <input
                            type="number"
                            className="form-control"
                            id="purchasePrice"
                            name="purchasePrice"
                            value={formData.purchasePrice}
                            onChange={handleChange}
                            placeholder="Enter Purchase Price"
                          />
                          {errors.purchasePrice && (
                            <small className="text-danger">
                              {errors.purchasePrice}
                            </small>
                          )}
                        </div>
                        <div className="form-group">
                          <label htmlFor="mrp">MRP</label>
                          <input
                            type="number"
                            className="form-control"
                            id="mrp"
                            name="mrp"
                            value={formData.mrp}
                            onChange={handleChange}
                            placeholder="Enter MRP"
                          />
                          {errors.mrp && (
                            <small className="text-danger">{errors.mrp}</small>
                          )}
                        </div>
                        <div className="form-group">
                          <label htmlFor="salePrice">Sale Price</label>
                          <input
                            type="number"
                            className="form-control"
                            id="salePrice"
                            name="salePrice"
                            value={formData.salePrice}
                            onChange={handleChange}
                            placeholder="Enter Sale Price"
                          />
                          {errors.salePrice && (
                            <small className="text-danger">
                              {errors.salePrice}
                            </small>
                          )}
                        </div>
                        <div className="form-group">
                          <label htmlFor="stock">Stock</label>
                          <input
                            type="number"
                            className="form-control"
                            id="stock"
                            name="stock"
                            value={formData.stock}
                            onChange={handleChange}
                            placeholder="Enter Stock Quantity"
                          />
                          {errors.stock && (
                            <small className="text-danger">
                              {errors.stock}
                            </small>
                          )}
                        </div>
                        {/* <div className="form-group">
                          <label htmlFor="sku">SKU</label>
                          <input
                            type="text"
                            className="form-control"
                            id="sku"
                            name="sku"
                            value={formData.sku}
                            onChange={handleChange}
                            placeholder="Enter SKU"
                          />
                          {errors.sku && (
                            <small className="text-danger">{errors.sku}</small>
                          )}
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-footer d-flex justify-content-end">
                <button type="submit" className="btn btn-dark">
                  Save Product
                </button>
              </div>
            </form>
          </section>
        </div>
      )}
    </>
  );
};

export default NewProduct;
