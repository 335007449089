import React, { useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import { ALL_MOBILES } from "../../utils/routeNames";

const AdminSidebar = () => {
  const [auth, setAuth] = useAuth();
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.clear("auth");
    setAuth({ user: null, token: "" });
    navigate("/login");
  };

  return (
    <aside
      className="main-sidebar sidebar-light-orange elevation-4 d-flex flex-column"
      style={{ height: "100vh" }}
    >
      {/* Brand Logo */}
      <a href="#" className="brand-link">
        <img
          src="/img/goparts-logo.png"
          alt="go_parts_Logo"
          className="brand-image"
          style={{ opacity: ".8", width: "150px" }}
        />
      </a>

      <div className="sidebar flex-grow-1">
        <nav className="mt-2">
          <ul
            className="nav nav-pills nav-sidebar flex-column d-flex flex-column"
            data-widget="treeview"
            role="menu"
            data-accordion="false"
          >
            {/* Dashboard */}
            <li className="nav-item">
              <NavLink to="home" className="nav-link">
                <i className="nav-icon fas fa-tachometer-alt"></i>
                <p>Dashboard</p>
              </NavLink>
            </li>

            {/* Customers */}
            <li className="nav-item">
              <NavLink
                to="customers"
                className={({ isActive }) =>
                  isActive ? "nav-link active" : "nav-link"
                }
              >
                <i className="nav-icon fas fa-users"></i>
                <p>Customers</p>
              </NavLink>
            </li>

            {/* Categories */}
            <li className="nav-item">
              <NavLink
                to="categories"
                className={({ isActive }) =>
                  isActive ? "nav-link active" : "nav-link"
                }
              >
                <i className="nav-icon fas fa-th"></i>{" "}
                {/* Changed to 'fa-th' for categories */}
                <p>Categories</p>
              </NavLink>
            </li>

            {/* Mobiles */}
            <li className="nav-item">
              <NavLink
                to={ALL_MOBILES}
                className={({ isActive }) =>
                  isActive ? "nav-link active" : "nav-link"
                }
              >
                <i className="nav-icon fas fa-mobile-alt"></i>{" "}
                {/* 'fa-mobile-alt' for mobiles */}
                <p>Mobiles</p>
              </NavLink>
            </li>

            {/* Products */}
            <li className="nav-item">
              <NavLink
                to="products"
                className={({ isActive }) =>
                  isActive ? "nav-link active" : "nav-link"
                }
              >
                <i className="nav-icon fas fa-box"></i>{" "}
                {/* 'fa-box' for a single product or item */}
                <p>Products</p>
              </NavLink>
            </li>

            {/* Orders */}
            <li className="nav-item">
              <NavLink
                to="orders"
                className={({ isActive }) =>
                  isActive ? "nav-link active" : "nav-link"
                }
              >
                <i className="nav-icon fas fa-shopping-cart"></i>{" "}
                {/* 'fa-shopping-cart' for orders */}
                <p>Orders</p>
              </NavLink>
            </li>

            {/* Coupons */}
            <li className="nav-item">
              <NavLink
                to="coupons"
                className={({ isActive }) =>
                  isActive ? "nav-link active" : "nav-link"
                }
              >
                <i className="nav-icon fas fa-ticket-alt"></i>{" "}
                {/* 'fa-ticket-alt' for coupons or discounts */}
                <p>Coupons</p>
              </NavLink>
            </li>
          </ul>
        </nav>
      </div>

      {/* Logout Button at the Bottom */}
      <div className="logout-section">
        <li className="nav-item mt-auto">
          <button className="btn btn-danger w-100" onClick={handleLogout}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="18"
              fill="currentColor"
              className="bi bi-box-arrow-right"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0z"
              />
              <path
                fillRule="evenodd"
                d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708z"
              />
            </svg>
            Logout
          </button>
        </li>
      </div>
    </aside>
  );
};

export default AdminSidebar;
