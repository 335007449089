import React, { useEffect, useState } from "react";
import { useUserContext } from "../../context/UserContext.jsx";
import axios from "axios";
import { useAuth } from "../../context/AuthContext";
import { useNavigate, useParams } from "react-router-dom";
import { Typeahead } from "react-bootstrap-typeahead";
import { BASE_URL } from "../../utils/routeNames.js";
import "react-bootstrap-typeahead/css/Typeahead.css";

function UpdateProduct() {
  const [product, setProduct] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [auth] = useAuth();
  const { id } = useParams();
  const navigate = useNavigate();
  const [file, setFile] = useState(null);
  const [preview, setPreview] = useState(null);

  const [categories, setCategories] = useState([]);

  const [selectedCategoryId, setSelectedCategoryId] = useState("");

  const [selectedCategory, setSelectedCategory] = useState("");
  const handleCategoryChange = (event) => {
    setSelectedCategoryId(event.target.value);
    // setSelectedCategory(
    //   categories.find((category) => category.id === selectedCategoryId)
    // );
  };
  const [isWarrantyApplicable, setIsWarrantyApplicable] = useState(false);
  const [mobileVariants, setMobileVariants] = useState([]);
  const [selectedMobileVariants, setSelectedMobileVariants] = useState([]);

  // Fetch categories and product details
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const res = await axios.get(`${BASE_URL}/category/allCategory`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${auth?.token}`,
          },
        });

        setCategories(
          res.data.categories.map((category) => ({
            id: category._id,
            label: category.category_name,
          }))
        );

        // console.log(
        //   "formattedCategories :",
        //   res.data.categories.map((category) => ({
        //     id: category._id,
        //     label: category.category_name,
        //   }))
        // );
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    const fetchProductDetails = async () => {
      try {
        const res = await axios.get(`${BASE_URL}/product/${id}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${auth?.token}`,
          },
        });
        console.log("product details are : ", res.data);
        console.log("isWarrantyApplicable", product.isWarrantyApplicable);

        setProduct(res.data);
        setIsWarrantyApplicable(res.data.isWarrantyApplicable);

        setSelectedMobileVariants(res.data.mobile.map((option) => option.id));
        setLoading(false);
      } catch (error) {
        console.error("Error fetching product details:", error);
        setError("Failed to load product details.");
        setLoading(false);
      }
    };

    // const fetchMobileVariants = async () => {
    //   // try {
    //   //   const res = await axios.get(`${BASE_URL}/mobile/Variants`);
    //   //   setMobileVariants(
    //   //     res.data.map((variant) => ({
    //   //       id: variant._id,
    //   //       label: `${variant.modelId.brandId.name}, ${variant.modelId.name}, ${variant.ram} RAM, ${variant.storage}, ${variant.color}`,
    //   //     }))
    //   //   );
    //   // } catch (error) {
    //   //   console.error("Error fetching mobile variants:", error);
    //   // }
    // };

    const fetchModelsORVariants = async () => {
      try {
        //fetching only models for start later u need to fetch variants as well
        const res = await axios.get(`${BASE_URL}/mobile/allModels`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${auth?.token}`,
          },
        });

        // Extract models array or handle fallback
        const data = Array.isArray(res.data) ? res.data : res.data.models || [];

        if (!Array.isArray(data)) {
          throw new Error("Expected an array but received something else.");
        }

        const formattedModels = data.map((model) => ({
          id: model._id,
          label: `${model.brandId.name}, ${model.name}`,
        }));
        setMobileVariants(formattedModels);
        // console.log("Models", res.data);
      } catch (error) {
        console.error("Error fetching mobile Models:", error);
      }
    };
    fetchCategories();
    fetchProductDetails();
    fetchModelsORVariants();
  }, [auth, id, selectedCategoryId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProduct({ ...product, [name]: value });
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      const reader = new FileReader();
      reader.onload = () => setPreview(reader.result);
      reader.readAsDataURL(selectedFile);
    }
  };
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleMobileVariantChange = (selectedOption) => {
    setSelectedMobileVariants(selectedOption.map((option) => option.id));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      let imageUrl = product.imageUrl;

      if (file) {
        const uploadData = new FormData();
        uploadData.append("image", file);

        const uploadResponse = await axios.post(
          `${BASE_URL}/upload/productImage`,
          uploadData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${auth?.token}`,
            },
          }
        );

        imageUrl = uploadResponse.data.imageUrl;
      }

      const updatedProductData = {
        ...product,
        imageUrl,
        // mobile: selectedMobileVariants,
      };
      // console.log("updatedProductData", updatedProductData);
      await axios.patch(
        `${BASE_URL}/product/${product._id}`,
        updatedProductData,
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        }
      );

      navigate(-1);
    } catch (error) {
      console.error("Error updating product:", error);
      setError("Failed to update product. Please try again.");
    }
  };

  const handleSelectChange = (selectedOption) => {
    setProduct((prev) => ({
      ...prev,
      category: selectedOption.length ? selectedOption[0].id : null,
    }));

    // setSelectedCategoryToUpdate(
    //   selectedOption.length ? selectedOption[0].id : null
    // );
    // console.log("selectedOption :", selectedOption[0].id);
  };

  const [showDuration, setShowDuration] = useState(false);
  useEffect(() => {
    setShowDuration(isWarrantyApplicable);
  }, [isWarrantyApplicable]);

  const handleWarranty = (value) => {
    setIsWarrantyApplicable(value === "true"); // Use setState to update the state
  };
  const handleWarrantyDuration = (value) => {
    // setFormData((prev) => ({
    //   ...prev,
    //   warrantyInMonth: parseInt(value, 10),
    // }));
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="content-wrapper">
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="text-dark">Update Product Details</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="#">Home</a>
                </li>
                <li className="breadcrumb-item active">
                  Update Product Details
                </li>
              </ol>
            </div>
          </div>
        </div>
      </section>
      <section className="content">
        {/* {message && <p>{message}</p>} */}
        <div className="card card-dark">
          <div className="card-header">
            <h3 className="card-title">{product.sku}</h3>
          </div>
          <form
            id="productForm"
            onSubmit={handleSubmit}
            className="card card-dark"
          >
            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label
                      htmlFor="mobileVariant"
                      className="control-label d-flex "
                    >
                      <div>Selected Mobile(s) : </div>{" "}
                      <div>
                        {product.mobile.map((mobile, index) => (
                          <tr key={index}>
                            <td>{mobile?.modelId?.brandId?.name || "N/A"}</td>
                            <td>{mobile?.modelId?.name || "N/A"}</td>
                          </tr>
                        ))}
                      </div>
                    </label>
                    {/* <Typeahead
                      id="mobileVariant"
                      // onChange={handleMobileVariantChange}

                      onChange={(selected) => {
                        handleMobileVariantChange(selected); // Call your existing variant change handler
                        setIsDropdownOpen(false); // Close the dropdown after selection
                      }}
                      options={mobileVariants}
                      selected={mobileVariants.filter((variant) =>
                        selectedMobileVariants.includes(variant.id)
                      )}
                      labelKey="label"
                      placeholder="Select to Change Mobile"
                      onFocus={() => setIsDropdownOpen(true)}
                      onBlur={() => setIsDropdownOpen(false)} // Close dropdown on blur
                      open={isDropdownOpen} // Control dropdown visibility
                      clearButton
                    /> */}
                  </div>

                  <div>
                    {/* Display the name of the selected category dynamically */}
                    <p>
                      Selected Category:{" "}
                      {product.category
                        ? product.category.category_name
                        : "None selected"}
                    </p>
                    {/* selectedCategoryId && product.category.id === selectedCategoryId */}
                    <label htmlFor="category">Select to Change Category</label>
                    <select
                      id="category"
                      className="form-control select2"
                      value={selectedCategoryId} // The selected category ID should be the value
                      onChange={handleCategoryChange} // Handle category selection change
                    >
                      <option value="">--Select a Category--</option>
                      {categories.map((category) => (
                        <option
                          key={category.id}
                          value={category.id}
                          selected={product.category._id === category.id} // Set selected if they match
                        >
                          {category.label}
                        </option>
                      ))}
                    </select>
                  </div>
                  <br />
                  <div className="card card-dark card-outline">
                    <div className="card-body">
                      {/* Product Image */}
                      <div className="form-group">
                        <label htmlFor="productImage" className="control-label">
                          Product Image
                        </label>
                        {/* <div className="custom-file">
                          <input
                            type="file"
                            className="custom-file-input"
                            id="productImage"
                            name="image"
                            onChange={handleFileChange}
                          />
                          <label
                            className="custom-file-label"
                            htmlFor="productImage"
                          >
                            Choose Product Image
                          </label>
                        </div>
                        {preview && (
                          <div className="image-preview mt-2">
                            <img
                              src={preview}
                              alt="Preview"
                              className="img-thumbnail"
                              style={{ width: "150px" }}
                            />
                          </div>
                          
                        )} */}
                        {/* Main Image */}
                        <div className="text-center mb-3">
                          <img
                            className="img-fluid rounded"
                            src={`${BASE_URL.replace("/api", "")}${
                              product.imageUrl
                            }`}
                            alt="product image"
                            style={{ height: "200px", objectFit: "contain" }}
                          />
                        </div>

                        {/* Additional Images Carousel */}
                        <label>Additional Photos</label>
                        {product && product.additionalPhotos?.length > 0 && (
                          <div className="row mb-3">
                            {product.additionalPhotos.map((photo, index) => (
                              <div
                                className="col-md-3 col-sm-6 mb-3" // Adjust grid column size based on screen size
                                key={photo + index} // Ensure unique key
                              >
                                <img
                                  src={`${BASE_URL.replace(
                                    "/api",
                                    ""
                                  )}${photo}`}
                                  className="img-fluid rounded" // Ensure responsive image
                                  alt={`Additional ${index + 1}`}
                                  style={{
                                    height: "100px",
                                    objectFit: "contain", // Fit the image nicely within the grid
                                    width: "100%",
                                  }}
                                />
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  {/* <div className="card card-dark card-outline">
                    <div className="card-header">
                      <h3 className="card-title">Additional Photos</h3>
                    </div>
                    <div className="card-body">
                      <div className="form-group">
                        <label htmlFor="photoUpload">Select Photos</label>

                        <div className="custom-file">
                          <input
                            type="file"
                            id="photoUpload"
                            className="custom-file-input"
                            multiple
                            // onChange={handleMultipleFileChange}
                            accept="image/*"
                          />
                          <label
                            className="custom-file-label"
                            htmlFor="imageUrl"
                          >
                            Choose Additional Photos
                          </label>
                          {/* {errors.product.additionalPhotos && (
                            <small className="text-danger d-block mt-2">
                              {errors.product.additionalPhotos}
                            </small>
                          )}
                        </div>
                      </div>

                      <div className="form-group">
                        <h5>Selected Photos</h5>
                        {product.additionalPhotos.length > 0 ? (
                          <div className="row">
                            {product.additionalPhotos.map((photo, index) => (
                              <div key={index} className="col-md-2">
                                <div className="card">
                                  <img
                                    src={photo.preview}
                                    alt="Selected"
                                    className="card-img-top"
                                    style={{
                                      height: "100px",
                                      objectFit: "cover",
                                    }}
                                  />
                                  <div className="card-body text-center">
                                    <button
                                      className="btn btn-danger btn-sm"
                                      // onClick={() => removePhoto(index)}
                                    >
                                      Remove
                                    </button>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        ) : (
                          <p>No photos selected.</p>
                        )}
                      </div>
                    </div>
                  </div> */}
                  <div className="form-group">
                    <label>Does this provide warranty?</label>
                    <div className="d-flex align-items-center ">
                      <div className="form-check mr-3">
                        <input
                          type="radio"
                          id="warrantyYes"
                          name="isWarrantyApplicable"
                          value="true"
                          checked={isWarrantyApplicable === true} // Compare boolean value
                          onChange={(e) => handleWarranty(e.target.value)}
                          className="form-check-input"
                        />
                        <label
                          htmlFor="warrantyYes"
                          className="form-check-label"
                        >
                          Yes
                        </label>
                      </div>

                      <div className="form-check">
                        <input
                          type="radio"
                          id="warrantyNo"
                          name="isWarrantyApplicable"
                          value="false"
                          checked={isWarrantyApplicable === false} // Compare boolean value
                          onChange={(e) => handleWarranty(e.target.value)}
                          className="form-check-input"
                        />
                        <label
                          htmlFor="warrantyNo"
                          className="form-check-label"
                        >
                          No
                        </label>
                      </div>
                    </div>
                    {showDuration && (
                      <div className="form-group">
                        <label htmlFor="duration">
                          Warranty(In no of Months)
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          id="duration"
                          name="duration"
                          // value={formData.duration}
                          // onChange={(e) => handleWarrantyDuration(e.target.value)}
                          min="1"
                          placeholder="Enter Warranty in  no of months"
                        />
                        {/* {errors.warrantyInMonth && (
                      <small className="text-danger">
                        {errors.warrantyInMonth}
                      </small>
                    )} */}
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-md-6">
                  {/* Product Name */}
                  <div className="form-group">
                    <label htmlFor="name" className="control-label">
                      Product Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      name="name"
                      value={product.name}
                      onChange={handleChange}
                      placeholder="Enter Product Name"
                      required
                    />
                  </div>

                  {/* Description */}
                  <div className="form-group">
                    <label htmlFor="description" className="control-label">
                      Description
                    </label>
                    <textarea
                      className="form-control"
                      id="description"
                      name="description"
                      value={product.description}
                      onChange={handleChange}
                      rows="3"
                      placeholder="Enter Description"
                      required
                    />
                  </div>
                  {/* Currency
                   <div className="form-group">
                    <label htmlFor="currency" className="control-label">
                      Currency
                    </label>
                    <select
                      className="form-control"
                      id="currency"
                      name="currency"
                      value={product.currency}
                      onChange={handleChange}
                      required
                    >
                      <option value="₹">₹ - United States Dollar</option>
                      <option value="EUR">EUR - Euro</option>
                      <option value="GBP">GBP - British Pound</option>
                      <option value="INR">INR - Indian Rupee</option>
                      <option value="JPY">JPY - Japanese Yen</option>
                      <option value="CAD">CAD - Canadian Dollar</option>
                      <option value="AUD">AUD - Australian Dollar</option>
                      <option value="CNY">CNY - Chinese Yuan</option>
                      <option value="SGD">SGD - Singapore Dollar</option>
                      <option value="AED">AED - UAE Dirham</option>
                    </select>
                  </div> */}

                  {/* Warranty 
                  <div className="form-group">
                    <label>Does this provide warranty?</label>
                    <div className="d-flex align-items-center ">
                      <div className="form-check mr-3">
                        <input
                          type="radio"
                          id="warrantyYes"
                          name="isWarrantyApplicable"
                          value="true"
                          checked={product.isWarrantyApplicable === true} // Compare boolean value
                          onChange={(e) => handleWarranty(e.target.value)}
                          className="form-check-input"
                        />
                        <label
                          htmlFor="warrantyYes"
                          className="form-check-label"
                        >
                          Yes
                        </label>
                      </div>

                      <div className="form-check">
                        <input
                          type="radio"
                          id="warrantyNo"
                          name="isWarrantyApplicable"
                          value="false"
                          checked={product.isWarrantyApplicable === false} // Compare boolean value
                          onChange={(e) => handleWarranty(e.target.value)}
                          className="form-check-input"
                        />
                        <label
                          htmlFor="warrantyNo"
                          className="form-check-label"
                        >
                          No
                        </label>
                      </div>
                    </div>
                  </div>

                  {showDuration && (
                    <div className="form-group">
                      <label htmlFor="duration">
                        Warranty(In no of Months)
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        id="duration"
                        name="duration"
                        value={product.duration}
                        onChange={(e) => handleWarrantyDuration(e.target.value)}
                        min="1"
                        placeholder="Enter Warranty in  no of months"
                      />
                      {/* {errors.warrantyInMonth && (
                          <small className="text-danger">
                            {errors.warrantyInMonth}
                          </small>
                        )}
                    </div> 
                  )}
                    Waraanty ends */}

                  <div className="card card-dark card-outline">
                    <div className="card-body">
                      {/* Purchase Price */}
                      <div className="form-group">
                        <label
                          htmlFor="purchasePrice"
                          className="control-label"
                        >
                          Purchase Price
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          id="purchasePrice"
                          name="purchasePrice"
                          value={product.purchasePrice}
                          onChange={handleChange}
                          min="0"
                          step="0.01"
                          placeholder="Enter Purchase Price"
                          required
                        />
                      </div>

                      {/* MRP */}
                      <div className="form-group">
                        <label htmlFor="mrp" className="control-label">
                          MRP
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          id="mrp"
                          name="mrp"
                          value={product.mrp}
                          onChange={handleChange}
                          min="0"
                          step="0.01"
                          placeholder="Enter MRP"
                          required
                        />
                      </div>

                      {/* Sale Price */}
                      <div className="form-group">
                        <label htmlFor="salePrice" className="control-label">
                          Sale Price
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          id="salePrice"
                          name="salePrice"
                          value={product.salePrice}
                          onChange={handleChange}
                          min="0"
                          step="0.01"
                          placeholder="Enter Sale Price"
                          required
                        />
                      </div>
                      {/* Commission */}
                      <div className="d-flex justify-content-between">
                        <div className="form-group">
                          <label htmlFor="commission" className="control-label">
                            Commission
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            id="commission"
                            name="commission"
                            value={product.commission || ""}
                            onChange={handleChange}
                            placeholder="Enter Commission"
                          />
                        </div>

                        {/* Commission Type */}
                        <div className="form-group">
                          <label
                            htmlFor="commissionType"
                            className="control-label"
                          >
                            Commission Type
                          </label>
                          <select
                            className="form-control"
                            id="commissionType"
                            name="commissionType"
                            value={product.commissionType || ""}
                            onChange={handleChange}
                          >
                            <option value="">Select Commission Type</option>
                            <option value="Percentage">Percentage</option>
                            <option value="Flat">Flat</option>
                          </select>
                        </div>
                      </div>
                      {/* Available Checkbox */}
                      <div className="form-group">
                        <div className="form-check">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="isAvailable"
                            name="isAvailable"
                            checked={product.isAvailable}
                            onChange={handleChange}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="isAvailable"
                          >
                            Is Available
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* End of Column 2 */}
              </div>
              {/* End of row */}
              <div className="row">
                <div className="col-md-6"></div>
                <div className="col-md-6"></div>
              </div>
            </div>

            <div className="card-footer">
              <button type="submit" className="btn btn-primary">
                Save Changes
              </button>
            </div>
          </form>
        </div>
      </section>
    </div>
  );
}

export default UpdateProduct;
