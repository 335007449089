import React, { useEffect, useState } from "react";
import axios from "axios";
import { BASE_URL } from "../../utils/routeNames";
import { useParams, useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";

const ViewOrder = () => {
  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { id } = useParams();
  const navigate = useNavigate();
  const [auth] = useAuth();

  useEffect(() => {
    const fetchOrder = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/order/${id}`, {
          headers: { Authorization: `Bearer ${auth?.token}` },
        });
        console.log("View Order", response.data);
        setOrder(response.data);
        setLoading(false);
      } catch (err) {
        setError(err.message || "Error fetching order details");
        setLoading(false);
      }
    };

    fetchOrder();
  }, [auth, id]);

  if (loading) {
    return (
      <div className="container text-center mt-5">
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="container text-center mt-5">
        <div className="alert alert-danger">{error}</div>
      </div>
    );
  }

  return (
    <div className="content-wrapper">
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>Order Details</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="#" onClick={() => navigate("/")}>
                    Home
                  </a>
                </li>
                <li className="breadcrumb-item active">Order Details</li>
              </ol>
            </div>
          </div>
        </div>
      </section>

      <section className="content">
        <div className="container-fluid">
          {/* Customer & Vendor Info */}
          <div className="card card-primary">
            <div className="card-header">
              <h3 className="card-title">Customer & Vendor Info</h3>
            </div>
            <div className="card-body">
              <p>
                <strong>Customer ID:</strong> {order.customerId}
              </p>
              <p>
                <strong>Vendor ID:</strong> {order.vendorId}
              </p>
            </div>
          </div>

          {/* Products */}
          <div className="card card-secondary">
            <div className="card-header">
              <h3 className="card-title">Products</h3>
            </div>
            <div className="card-body">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Product ID</th>
                    <th>Quantity</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  {order.products.map((product, index) => (
                    <tr key={index}>
                      <td>{product.productId}</td>
                      <td>{product.quantity}</td>
                      <td>
                        {order.currency}
                        {product.total}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          {/* Shipping Address */}
          <div className="card card-info">
            <div className="card-header">
              <h3 className="card-title">Shipping Address</h3>
            </div>
            <div className="card-body">
              <p>{order.shippingAddress.addressLine1}</p>
              <p>{order.shippingAddress.addressLine2}</p>
              <p>
                {order.shippingAddress.city}, {order.shippingAddress.state},{" "}
                {order.shippingAddress.postalCode}, {order.shippingAddress.country}
              </p>
            </div>
          </div>

          {/* Payment and Order Info */}
          <div className="card card-warning">
            <div className="card-header">
              <h3 className="card-title">Payment & Order Info</h3>
            </div>
            <div className="card-body">
              <p>
                <strong>Payment Method:</strong> {order.paymentMethod}
              </p>
              <p>
                <strong>Payment Status:</strong> {order.paymentStatus}
              </p>
              <p>
                <strong>Order Status:</strong> {order.orderStatus}
              </p>
              <p>
                <strong>Delivery Status:</strong> {order.deliveryDetails.deliveryStatus}
              </p>
              <p>
                <strong>Shipping Cost:</strong> {order.currency}
                {order.shippingCost}
              </p>
              <p>
                <strong>Total Amount:</strong> {order.currency}
                {order.totalAmount}
              </p>
              <p>
                <strong>Discount:</strong> {order.currency}
                {order.discount}
              </p>
              <p>
                <strong>Order Date:</strong>{" "}
                {new Date(order.orderDate).toLocaleDateString()}
              </p>
            </div>
          </div>

          <div className="text-center">
            <button
              className="btn btn-primary"
              onClick={() => navigate(-1)}
            >
              Back to Orders
            </button>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ViewOrder;
