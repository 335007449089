import React, { useState, useEffect } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import axios from "axios";
import { BASE_URL } from "../../utils/routeNames";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

import { useAuth } from "../../context/AuthContext";
const CreateMobile = () => {
  const [auth] = useAuth();
  const navigate = useNavigate();
  const [brandOptions, setBrandOptions] = useState([]);
  const [modelOptions, setModelOptions] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [selectedModel, setSelectedModel] = useState(null);
  const [selectedBrandImageUrl, setSelectedBrandImageUrl] = useState(null);
  const [selectedModelImageUrl, setSelectedModelImageUrl] = useState(null);
  const [variants, setVariants] = useState([
    { storage: "", ram: "", color: "" },
  ]);
  const [selectedBrandImage, setSelectedBrandImage] = useState(null);
  const [selectedModelImage, setSelectedModelImage] = useState(null);

  useEffect(() => {
    axios
      .get(`${BASE_URL}/mobile/brands`, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then((response) => setBrandOptions(response.data))
      .catch((error) => console.error("Error fetching brands:", error));
  }, []);

  useEffect(() => {
    if (selectedBrand) {
      setModelOptions([]);
      console.log("selected brand", selectedBrand.name, selectedBrand._id);

      axios
        .get(
          `${BASE_URL}/mobile/getModelsForCreatingVariants?brandId=${selectedBrand._id}`,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${auth?.token}`,
            },
          }
        )

        .then((response) => setModelOptions(response.data))
        .catch((error) => console.error("Error fetching models:", error));
    }
  }, [selectedBrand]);

  const handleImageChange = (e, setImage) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => setImage(reader.result);
      reader.readAsDataURL(file);
    }
  };

  const handleVariantChange = (index, field, value) => {
    const updatedVariants = [...variants];
    updatedVariants[index][field] = value;
    setVariants(updatedVariants);
  };

  const addVariant = () => {
    setVariants([...variants, { storage: "", ram: "", color: "" }]);
  };

  const removeVariant = (index) => {
    const updatedVariants = variants.filter((_, i) => i !== index);
    setVariants(updatedVariants);
  };

  const handleCreateVariants = (e) => {
    e.preventDefault();
    e.preventDefault();

    if (!selectedBrand || !selectedModel) {
      alert("Please select a brand and model.");
      return;
    }

    const formData = {
      modelId: selectedModel._id, // Pass the model's ID explicitly
      variants, // List of variants
    };
    console.log(`formData : ${formData} `);
    axios
      .post(`${BASE_URL}/mobile/createVariant`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then((response) => {
        // console.log("Variants added:", response.data);
        // navigate(-1);
        if (response.status === 201) {
          navigate(-1);
        } else {
          toast.error(response.data.message);
          console.log("Status ! 201", response.data.message);
        }
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          toast.error(error.response.data.message); // Use the message from the server
        } else {
          toast.error("An unexpected error occurred");
        }
        console.error("Error creating Variants:", error);
      });
  };

  const handleNewModelName = (input) => {
    const model = {
      name: input,
      modelImage: selectedModelImage,
    };
    setSelectedModel(model);
    console.log("NewMOdelName", model.name);
  };

  return (
    <div className="content-wrapper ">
      <div className="card card-success card-outline ">
        <div className="card-header bg-primary text-white text-center">
          <h5 className="mb-0">Create Mobile with variants</h5>
        </div>

        <section className="content">
          <form onSubmit={handleCreateVariants}>
            <div className="row align-items-center">
              <div className="col-md-5 m-2 ">
                {/* Brand Card */}
                <div className="card card-primary">
                  <div className="card-header">
                    <h3 className="card-title">Brand Details</h3>
                  </div>
                  <div className="card-body">
                    <div className="form-group">
                      <label>Brand Name</label>
                      <Typeahead
                        id="brand-typeahead"
                        options={brandOptions}
                        labelKey="name"
                        placeholder="Choose a brand..."
                        onChange={(selected) => setSelectedBrand(selected[0])}
                        selected={selectedBrand ? [selectedBrand] : []}
                      />
                    </div>
                    {/* <div className="form-group">
                    <label>Brand Image</label>
                    <div className="custom-file">
                      <input
                        className="custom-file-input"
                        type="file"
                        accept="image/*"
                        onChange={(e) =>
                          handleImageChange(e, setSelectedBrandImage)
                        }
                      />
                      <label className="custom-file-label" htmlFor="fileUpload">
                        Choose image for Brand
                      </label>
                    </div>
                    {selectedBrandImage && (
                      <img
                        src={selectedBrandImage}
                        alt="Brand Preview"
                        className="img-thumbnail mt-2"
                        width="100"
                      />
                    )}
                  </div> */}
                  </div>
                </div>
              </div>
              <div className="col-md-5 m-2">
                {/* Model Card */}
                <div className="card card-secondary ">
                  <div className="card-header">
                    <h3 className="card-title">Model Details</h3>
                  </div>
                  <div className="card-body">
                    <div className="form-group">
                      <label>Model Name</label>
                      <Typeahead
                        id="model-typeahead"
                        options={modelOptions}
                        labelKey="name"
                        placeholder="Choose a model..."
                        onChange={(selected) => setSelectedModel(selected[0])}
                        selected={selectedModel ? [selectedModel] : []}
                        disabled={!selectedBrand}
                        onInputChange={(input) => handleNewModelName(input)}
                      />
                    </div>
                    {/* <div className="form-group">
                    <label>Brand Image</label>
                    <div className="custom-file">
                      <input
                        className="custom-file-input"
                        type="file"
                        accept="image/*"
                        onChange={(e) =>
                          handleImageChange(e, setSelectedModelImage)
                        }
                      />
                      <label className="custom-file-label" htmlFor="fileUpload">
                        Choose image for Model
                      </label>
                    </div>
                    {selectedBrandImage && (
                      <img
                        src={selectedBrandImage}
                        alt="Brand Preview"
                        className="img-thumbnail mt-2"
                        width="100"
                      />
                    )}
                  </div> */}
                    {/* <div className="form-group">
                <label>Model Image</label>
                
                <input
                  className="custom-file-input"
                  type="file"
                  accept="image/*"
                  onChange={(e) => handleImageChange(e, setSelectedModelImage)}
                />
                {selectedModelImage && (
                  <img
                    src={selectedModelImage}
                    alt="Model Preview"
                    className="img-thumbnail mt-2"
                    width="100"
                  />
                )}
              </div> */}
                  </div>
                </div>
              </div>
            </div>
            {/* Variants Card */}
            <div className="card card-info ml-4 mr-4">
              <div className="card-header d-flex justify-content-between align-items-end">
                <h3 className="card-title">Variants</h3>
                <button
                  type="button"
                  className="btn btn-sm btn-success"
                  onClick={addVariant}
                >
                  Add Variant
                </button>
              </div>
              <div className="card-body">
                <div className="row">
                  {variants.map((variant, index) => (
                    <div key={index} className="col-md-4 mb-4">
                      <div className="card bg-light">
                        <div className="card-header">
                          <h5 className="card-title">Variant {index + 1}</h5>
                          <button
                            type="button"
                            className="close"
                            onClick={() => removeVariant(index)}
                          >
                            &times;
                          </button>
                        </div>
                        <div className="card-body">
                          <div className="form-group">
                            <label>Storage</label>
                            <input
                              type="text"
                              className="form-control"
                              value={variant.storage}
                              onChange={(e) =>
                                handleVariantChange(
                                  index,
                                  "storage",
                                  e.target.value
                                )
                              }
                              placeholder="e.g., 128GB"
                            />
                          </div>
                          <div className="form-group">
                            <label>RAM</label>
                            <input
                              type="text"
                              className="form-control"
                              value={variant.ram}
                              onChange={(e) =>
                                handleVariantChange(
                                  index,
                                  "ram",
                                  e.target.value
                                )
                              }
                              placeholder="e.g., 4GB"
                            />
                          </div>
                          <div className="form-group">
                            <label>Color</label>
                            <input
                              type="text"
                              className="form-control"
                              value={variant.color}
                              onChange={(e) =>
                                handleVariantChange(
                                  index,
                                  "color",
                                  e.target.value
                                )
                              }
                              placeholder="e.g., Blue"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="card card-footer">
              <button type="submit" className="btn btn-primary mt-4">
                Submit
              </button>
            </div>
            {/* Submit Button */}
          </form>
        </section>
      </div>
    </div>
  );
};

export default CreateMobile;
