import React, { useState } from "react";
import axios from "axios";
import { useAuth } from "../../context/AuthContext.jsx";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../../utils/routeNames.js";

function NewUser() {
  const [auth] = useAuth();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const [userForm, setUserForm] = useState({
    userType: "Customer",
    // email: "",
    // userName: "",
    // mobileNumber: "",
    // auth: {
    //   email: "",
    //   userName: "",
    //   mobileNumber: "",
    // },
    password: "123456",
    otp: { code: "", expiresAt: "" },
    isActive: true,
    isBanned: false,
    businessDetails: {
      businessName: "",
      businessOutSidePhoto: "",
      businessAddress: {
        shopNumber: "",
        buildingName: "",
        floorNumber: "",
        streetName: "",
        landmark: "",
        area: "",
        city: "",
        state: "",
        country: "",
        pincode: "",
      },
      bankDetails: {
        bankAccountNumber: "Null",
        ifscCode: "Null",
        bankName: "Null",
      },
      operationalHours: { open: "09:00 AM", close: "06:00 PM" },
      mapCoordinates: { latitude: "", longitude: "" },
      businessPlacePhotos: [],
      businessType: "Proprietor",
      businessCategory: "Retail",
      uploadBusinessDocs: [],
      rating: 0,
    },
    pocDetails: {
      name: "",
      pocPhoto: "",
      mobileNumber: "",
      email: "",
    },
    // addressBook: [],
    preferredPaymentMethod: "Cash on Delivery",
    orderHistory: [],
    permissions: {
      canManageVendors: false,
      canManageOrders: false,
      canManageCustomers: false,
    },
    activityLog: [],
    resetPasswordToken: null,
    resetPasswordExpires: null,
    emailVerified: true,
    phoneVerified: true,
    notificationSettings: {
      email: true,
      sms: true,
      appPush: false,
    },
    preferredLanguage: "en",
    currencyPreference: "₹",
  });

  const [errors, setErrors] = useState({});

  const validate = () => {
    const newErrors = {};
    if (!userForm.pocDetails.name) newErrors.pocName = "POC name is required.";
    if (!userForm.pocDetails.mobileNumber)
      newErrors.pocMobileNumber = "POC mobile number is required.";
    if (!userForm.pocDetails.email)
      newErrors.pocEmail = "POC email is required.";

    // Bank Details validation
    // if (!userForm.businessDetails.bankDetails.bankAccountNumber)
    //   newErrors.bankAccountNumber = "Bank account number is required.";
    // if (!userForm.businessDetails.bankDetails.ifscCode)
    //   newErrors.ifscCode = "IFSC code is required.";
    // if (!userForm.businessDetails.bankDetails.bankName)
    //   newErrors.bankName = "Bank name is required.";

    // Business Details validation
    if (!userForm.businessDetails.businessName)
      newErrors.businessName = "Business name is required.";
    const addressFields = [
      "shopNumber",
      "buildingName",
      "floorNumber",
      "streetName",
      "landmark",
      "area",
      "city",
      "state",
      "country",
      "pincode",
    ];
    addressFields.forEach((field) => {
      if (!userForm.businessDetails.businessAddress[field]) {
        newErrors[field] = `${field.replace(/([A-Z])/g, " $1")} is required.`;
      }
    });
    console.log("newErrors", newErrors);
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleCreate = async () => {
    if (!validate()) return;
    setError(null);
    setLoading(true); // Start loader
    const userName = userForm["pocDetails"].email.split("@")[0];
    const mobileNumber = userForm["pocDetails"].mobileNumber;
    const email = userForm["pocDetails"].email;

    const userData = {
      ...userForm,
      userName: userName,
      mobileNumber: mobileNumber,
      email: email,
    };
    try {
      const res = await axios.post(`${BASE_URL}/user/newUser`, userData, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      });
      console.log("Created User:", res.data);
      if (res.status === 201) {
        navigate(-1);
      } else {
        setError(res.data.message);
        console.log("Status ! 201", res.data.message);
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        setError(error.response.data.message); // Use the message from the server
      } else {
        setError("An unexpected error occurred");
      }
      console.error("Error creating user:", error);
    } finally {
      setLoading(false); // Stop loader
    }
  };

  const handleInputChange = (e, section, subsection) => {
    const { name, value } = e.target;
    setUserForm((prev) => {
      if (subsection) {
        return {
          ...prev,
          [section]: {
            ...prev[section],
            [subsection]: { ...prev[section][subsection], [name]: value },
          },
        };
      } else if (section) {
        return { ...prev, [section]: { ...prev[section], [name]: value } };
      } else {
        return { ...prev, [name]: value };
      }
    });
  };
  const [error, setError] = useState("");
  return (
    <div className="content-wrapper">
      <section className="content-header">
        <h1 className="text-dark">Create User</h1>
      </section>
      <section className="content">
        <div className="row">
          {/* POC Details */}
          <div className="col-md-6">
            <div className="card card-secondary">
              <div className="card-header">
                <h3 className="card-title">Point of Contact (POC) Details</h3>
              </div>
              <div className="card-body">
                {["name", "mobileNumber", "email"].map((field) => (
                  <div className="form-group" key={field}>
                    <label>
                      {field.charAt(0).toUpperCase() + field.slice(1)}
                    </label>
                    <input
                      type="text"
                      name={field}
                      value={userForm.pocDetails[field]}
                      onChange={(e) => handleInputChange(e, "pocDetails")}
                      className="form-control"
                    />

                    {errors[
                      `poc${field.charAt(0).toUpperCase() + field.slice(1)}`
                    ] && (
                      <small className="text-danger">
                        {
                          errors[
                            `poc${
                              field.charAt(0).toUpperCase() + field.slice(1)
                            }`
                          ]
                        }
                      </small>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
          {/* Login Details */}
          {/* <div className="col-md-6">
            <div className="card card-secondary">
              <div className="card-header">
                <h3 className="card-title">Login Details</h3>
              </div>
              <div className="card-body">
                {["email", "userName", "mobileNumber"].map((field) => (
                  <div className="form-group" key={field}>
                    <label>
                      {field.charAt(0).toUpperCase() + field.slice(1)}
                    </label>
                    <input
                      type="text"
                      name={field}
                      value={userForm.auth[field]}
                      onChange={(e) => handleInputAuth(e, "auth")}
                      className="form-control"
                    />
                    {errors[field] && (
                      <small className="text-danger">{errors[field]}</small>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div> */}
          {/* Business Details */}
          <div className="col-md-12">
            <div className="card card-secondary">
              <div className="card-header">
                <h3 className="card-title">Business Details</h3>
              </div>
              <div className="card-body">
                <div className="row">
                  {/* Left Column */}
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Business Name</label>
                      <input
                        type="text"
                        name="businessName"
                        value={userForm.businessDetails.businessName}
                        onChange={(e) =>
                          handleInputChange(e, "businessDetails")
                        }
                        className="form-control"
                      />
                      {errors.businessName && (
                        <small className="text-danger">
                          {errors.businessName}
                        </small>
                      )}
                    </div>
                    <div className="form-group">
                      <label>Shop Number</label>
                      <input
                        type="text"
                        name="shopNumber"
                        value={
                          userForm.businessDetails.businessAddress.shopNumber
                        }
                        onChange={(e) =>
                          handleInputChange(
                            e,
                            "businessDetails",
                            "businessAddress"
                          )
                        }
                        className="form-control"
                      />
                      {errors.businessName && (
                        <small className="text-danger">
                          {errors.businessName}
                        </small>
                      )}
                    </div>
                    <div className="form-group">
                      <label>Building Name</label>
                      <input
                        type="text"
                        name="buildingName"
                        value={
                          userForm.businessDetails.businessAddress.buildingName
                        }
                        onChange={(e) =>
                          handleInputChange(
                            e,
                            "businessDetails",
                            "businessAddress"
                          )
                        }
                        className="form-control"
                      />
                      {errors.shopNumber && (
                        <small className="text-danger">
                          {errors.shopNumber}
                        </small>
                      )}
                    </div>
                    <div className="form-group">
                      <label>Floor Number</label>
                      <input
                        type="text"
                        name="floorNumber"
                        value={
                          userForm.businessDetails.businessAddress.floorNumber
                        }
                        onChange={(e) =>
                          handleInputChange(
                            e,
                            "businessDetails",
                            "businessAddress"
                          )
                        }
                        className="form-control"
                      />
                      {errors.floorNumber && (
                        <small className="text-danger">
                          {errors.floorNumber}
                        </small>
                      )}
                    </div>
                    <div className="form-group">
                      <label>Street Name</label>
                      <input
                        type="text"
                        name="streetName"
                        value={
                          userForm.businessDetails.businessAddress.streetName
                        }
                        onChange={(e) =>
                          handleInputChange(
                            e,
                            "businessDetails",
                            "businessAddress"
                          )
                        }
                        className="form-control"
                      />
                      {errors.streetName && (
                        <small className="text-danger">
                          {errors.streetName}
                        </small>
                      )}
                    </div>
                    <div className="form-group">
                      <label>Landmark</label>
                      <input
                        type="text"
                        name="landmark"
                        value={
                          userForm.businessDetails.businessAddress.landmark
                        }
                        onChange={(e) =>
                          handleInputChange(
                            e,
                            "businessDetails",
                            "businessAddress"
                          )
                        }
                        className="form-control"
                      />
                      {errors.landmark && (
                        <small className="text-danger">{errors.landmark}</small>
                      )}
                    </div>
                  </div>

                  {/* Right Column */}
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Area</label>
                      <input
                        type="text"
                        name="area"
                        value={userForm.businessDetails.businessAddress.area}
                        onChange={(e) =>
                          handleInputChange(
                            e,
                            "businessDetails",
                            "businessAddress"
                          )
                        }
                        className="form-control"
                      />
                      {errors.area && (
                        <small className="text-danger">{errors.area}</small>
                      )}
                    </div>
                    <div className="form-group">
                      <label>City</label>
                      <input
                        type="text"
                        name="city"
                        value={userForm.businessDetails.businessAddress.city}
                        onChange={(e) =>
                          handleInputChange(
                            e,
                            "businessDetails",
                            "businessAddress"
                          )
                        }
                        className="form-control"
                      />
                      {errors.city && (
                        <small className="text-danger">{errors.city}</small>
                      )}
                    </div>
                    <div className="form-group">
                      <label>State</label>
                      <input
                        type="text"
                        name="state"
                        value={userForm.businessDetails.businessAddress.state}
                        onChange={(e) =>
                          handleInputChange(
                            e,
                            "businessDetails",
                            "businessAddress"
                          )
                        }
                        className="form-control"
                      />
                      {errors.state && (
                        <small className="text-danger">{errors.state}</small>
                      )}
                    </div>
                    <div className="form-group">
                      <label>Country</label>
                      <input
                        type="text"
                        name="country"
                        value={userForm.businessDetails.businessAddress.country}
                        onChange={(e) =>
                          handleInputChange(
                            e,
                            "businessDetails",
                            "businessAddress"
                          )
                        }
                        className="form-control"
                      />
                      {errors.country && (
                        <small className="text-danger">{errors.country}</small>
                      )}
                    </div>
                    <div className="form-group">
                      <label>Pincode</label>
                      <input
                        type="text"
                        name="pincode"
                        value={userForm.businessDetails.businessAddress.pincode}
                        onChange={(e) =>
                          handleInputChange(
                            e,
                            "businessDetails",
                            "businessAddress"
                          )
                        }
                        className="form-control"
                      />
                      {errors.pincode && (
                        <small className="text-danger">{errors.pincode}</small>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            {error && (
              <div className="alert alert-danger text-center" role="alert">
                {error}
              </div>
            )}
            <div className="form-group">
              <button
                className="btn btn-success float-right"
                onClick={handleCreate}
                disabled={loading}
              >
                {loading ? "Creating..." : "Create User"}
              </button>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default NewUser;
