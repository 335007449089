import React, { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../context/AuthContext"; // Import useAuth to access auth token
import { BASE_URL } from "../../utils/routeNames.js";
import * as Routes from "../../utils/routeNames.js";

import AddMobilesForProductDialog from "./addMobilesForProduct.js";
function ViewProduct() {
  const [productDetails, setProductDetails] = useState(null);
  const [auth] = useAuth(); // Access the auth context to get the token
  const navigate = useNavigate();
  const { id } = useParams(); // Assuming product ID is passed in the route as a parameter
  const [showDialog, setShowDialog] = useState(false);
  const fetchProductDetails = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/product/${id}`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`, // Add bearer token in headers
        },
      });
      setProductDetails(response.data);
      productDetails.additionalPhotos.map((prod) =>
        console.log("prod.additionalPhotos", prod)
      );
      console.log("Response Product", response.data);
    } catch (error) {
      console.error("Error fetching product details:", error);
    }
  };

  useEffect(() => {
    if (id) {
      fetchProductDetails();
    }
  }, [id, auth]);

  const handleEdit = () => {
    console.log(`${Routes.UPADATE_PRODUCT}/${id}`);
    navigate(`${Routes.UPADATE_PRODUCT}/${id}`, { replace: true });
  };

  const handleDelete = async () => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this product?"
    );
    if (confirmDelete) {
      try {
        await axios.delete(`${BASE_URL}/product/${id}`, {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        });
        alert("Product deleted successfully.");
        navigate(-1); // Navigate to the product list page after deletion
      } catch (error) {
        console.error("Error deleting product:", error);
        alert("Failed to delete product.");
      }
    }
  };

  return (
    <>
      <div className="content-wrapper">
        <section className="content-header d-flex justify-content-between align-items-center">
          <h1>Product Details</h1>
          <div>
            {/* Delete Button
            <button className="btn btn-danger mr-2" onClick={handleDelete}>
              Delete Product
            </button>
            {/* Edit Button 
            <button className="btn btn-primary" onClick={handleEdit}>
              Edit Product
            </button>*/}
          </div>
        </section>
        <section className="content container-fluid">
          {productDetails ? (
            <div className="row">
              {/* Product Details */}
              <div className="col-md-7 mt-1">
                <div className="card card-outline card-secondary shadow-sm">
                  <div className="card-header">
                    <h3 className="card-title">{productDetails.name}</h3>
                  </div>
                  <div className="card-body">
                    {/* Main Image */}
                    <div className="text-center mb-3">
                      <img
                        className="img-fluid rounded"
                        src={`${BASE_URL.replace("/api", "")}${
                          productDetails.imageUrl
                        }`}
                        alt="product image"
                        style={{ height: "200px", objectFit: "contain" }}
                      />
                    </div>

                    {/* Additional Images Carousel */}
                    <label>Additional Photos</label>
                    {productDetails &&
                      productDetails.additionalPhotos?.length > 0 && (
                        <div className="row mb-3">
                          {productDetails.additionalPhotos.map(
                            (photo, index) => (
                              <div
                                className="col-md-3 col-sm-6 mb-3" // Adjust grid column size based on screen size
                                key={photo + index} // Ensure unique key
                              >
                                <img
                                  src={`${BASE_URL.replace(
                                    "/api",
                                    ""
                                  )}${photo}`}
                                  className="img-fluid rounded" // Ensure responsive image
                                  alt={`Additional ${index + 1}`}
                                  style={{
                                    height: "100px",
                                    objectFit: "contain", // Fit the image nicely within the grid
                                    width: "100%",
                                  }}
                                />
                              </div>
                            )
                          )}
                        </div>
                      )}

                    {/* Description */}
                    <div className="mb-3">
                      <strong>Description:</strong>
                      <p>{productDetails.description || "N/A"}</p>
                    </div>

                    {/* Mobile Details */}
                    <div>
                      <button
                        onClick={() => setShowDialog(true)}
                        className="btn btn-warning ml-2"
                      >
                        <i className="fas fa-plus mr-1"></i> Add Mobile
                      </button>

                      {/* Render dialog only once */}
                      {showDialog && (
                        <AddMobilesForProductDialog
                          mobiles={productDetails.mobile}
                          product={productDetails}
                          loadProductDetails={fetchProductDetails}
                          // productId={productDetails._id}
                          // productName={productDetails.name}
                          authToken={auth?.token}
                          onClose={() => setShowDialog(false)}
                          showModal={showDialog}
                        />
                      )}
                    </div>
                    <div className="mb-3">
                      <strong>Mobile Details:</strong>
                      {productDetails?.mobile?.length > 0 ? (
                        <div className="table-responsive">
                          <table className="table table-bordered table-sm">
                            <thead className="table-light">
                              <tr>
                                <th>#</th>
                                <th>Brand</th>
                                <th>Model</th>
                              </tr>
                            </thead>
                            <tbody>
                              {productDetails.mobile.map((mobile, index) => (
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td>
                                    {mobile?.modelId?.brandId?.name || "N/A"}
                                  </td>
                                  <td>{mobile?.modelId?.name || "N/A"}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      ) : (
                        <p>No mobile details available.</p>
                      )}
                    </div>

                    {/* Tags and Keywords */}
                    {/* <div className="mb-3">
                      <strong>Tags:</strong>
                      <p>{productDetails.tags || "N/A"}</p>
                    </div>
                    <div className="mb-3">
                      <strong>Keywords:</strong>
                      <p>{productDetails.keywords || "N/A"}</p>
                    </div> */}
                  </div>
                </div>
              </div>

              {/* Pricing and Additional Information */}
              <div className="col-md-5 mt-1">
                {/* Pricing Information */}
                <div className="card card-outline card-secondary shadow-sm mb-3">
                  <div className="card-header">
                    <h3 className="card-title">Pricing Information</h3>
                  </div>
                  <div className="card-body">
                    <div className="mb-2">
                      <strong>SKU:</strong>
                      <p>{productDetails.sku}</p>
                    </div>
                    <div className="mb-2">
                      <strong>Purchase Price:</strong>
                      <p>
                        {productDetails.currency} {productDetails.purchasePrice}
                      </p>
                    </div>
                    <div className="mb-2">
                      <strong>MRP:</strong>
                      <p>
                        {productDetails.currency} {productDetails.mrp}
                      </p>
                    </div>
                    <div className="mb-2">
                      <strong>Sale Price:</strong>
                      <p>
                        {productDetails.currency} {productDetails.salePrice}
                      </p>
                    </div>
                    <div className="mb-2">
                      <strong>Sale Price With Commission:</strong>
                      <p>
                        {productDetails.currency}{" "}
                        {productDetails.salePriceWithCommission}
                      </p>
                    </div>
                    <div className="mb-2">
                      <strong>Commission:</strong>
                      <p>
                        {productDetails.commission}{" "}
                        {productDetails.commissionType}
                      </p>
                    </div>
                  </div>
                </div>

                {/* Additional Information */}
                <div className="card card-secondary card-outline shadow-sm">
                  <div className="card-header">
                    <h3 className="card-title">Additional Information</h3>
                  </div>
                  <div className="card-body">
                    <div className="mb-2">
                      <strong>Category:</strong>
                      <p>{productDetails?.category?.category_name || "N/A"}</p>
                    </div>
                    <div className="mb-2">
                      <strong>Stock:</strong>
                      <p>{productDetails.stock || "N/A"}</p>
                    </div>
                    <div className="mb-2">
                      <strong>Created By:</strong>
                      <p>{productDetails.createdBy.userName}</p>
                    </div>
                    <div className="mb-2">
                      <strong>Created On:</strong>
                      <p>
                        {moment(productDetails.createdAt).format(
                          "DD MMMM YYYY, h:mm A"
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <p>Loading product details...</p>
          )}
        </section>
      </div>
    </>
  );
}

export default ViewProduct;
