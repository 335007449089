import React, { useEffect, useState } from "react";
import axios from "axios";
import { BASE_URL } from "../../utils/routeNames";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { useAuth } from "../../context/AuthContext";
import toast from "react-hot-toast";
const CreateModelByBrand = () => {
  const navigate = useNavigate();
  const [auth] = useAuth();
  const [brandOptions, setBrandOptions] = useState([]);
  const [modelCommaSeparatedValues, setModelCommaSeparatedValues] =
    useState("");
  const [selectedBrand, setSelectedBrand] = useState(null);

  const handleInputChange = (e) => setModelCommaSeparatedValues(e.target.value);

  // Fetch brand options
  useEffect(() => {
    axios
      .get(`${BASE_URL}/mobile/brands`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then((response) => setBrandOptions(response.data))
      .catch((error) => console.error("Error fetching brands:", error));
  }, []);

  const handleInsertModels = () => {
    if (!selectedBrand) {
      alert("Please select a brand!");
      return;
    }

    const models = modelCommaSeparatedValues
      .split(",")
      .map((name) => ({ name: name.trim(), modelImage: "" }))
      .filter((model) => model.name); // Remove empty models
    // console.log("models", models);

    const data = {
      brandId: selectedBrand._id,
      models,
    };
    console.log("Map to create ", {
      brandId: selectedBrand._id,
      models,
    });

    // Send to backend
    axios
      .post(`${BASE_URL}/mobile/createModelsForBrand`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then((response) => {
        console.log("Models created:", response.data);
        toast.success("Models created successfully!");
        navigate(-1);
      })
      .catch((error) => {
        console.error("Error adding models:", error);
        toast.error("Failed to create models. Please try again.");
      });
  };

  return (
    <div className="content-wrapper">
      <section className="content-header">
        <h1 className="font-weight-bold">Create Models by Brand</h1>
      </section>
      <div className="row align-items-center">
        <div className="col-md-6 m-2">
          <div className="card card-primary">
            <div className="card-header">
              <h3 className="card-title">Brand Details</h3>
            </div>
            <div className="card-body">
              <Select
                options={brandOptions}
                value={selectedBrand}
                onChange={(selected) => setSelectedBrand(selected)}
                getOptionLabel={(e) => e.name}
                getOptionValue={(e) => e._id}
                placeholder="Choose a brand..."
              />
            </div>
          </div>
        </div>
        <div className="col-md-12 m-2">
          <div className="card card-secondary">
            <div className="card-header">
              <h3 className="card-title">Model Names (Comma Separated)</h3>
            </div>
            <div className="card-body">
              <textarea
                value={modelCommaSeparatedValues}
                onChange={handleInputChange}
                placeholder="Enter comma-separated model names..."
                style={{
                  width: "100%",
                  height: "150px",
                  padding: "10px",
                  fontSize: "16px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                }}
              ></textarea>
            </div>
            <div className="card-footer">
              <button
                onClick={handleInsertModels}
                className="btn btn-primary mt-3"
              >
                Create Models
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateModelByBrand;
