import React, { useState } from "react";
import axios from "axios";
import { BASE_URL } from "../../utils/routeNames";
import { useAuth } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";

const CreateCoupon = () => {
  const [auth] = useAuth();
  const [formData, setFormData] = useState({
    code: "",
    description: "",
    discountType: "Percentage",
    discountValue: "",
    minOrderAmount: "",
    maxDiscountAmount: "",
    startDate: "",
    endDate: "",
    usageLimit: "",
    perUserLimit: "",
    isActive: true,
    createdBy: auth?.user._id,
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const navigate = useNavigate();

  // Handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      // Send POST request to the API to create a new coupon
      const response = await axios.post(
        `${BASE_URL}/coupon/coupons`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${auth?.token}`,
          },
        }
      );

      setLoading(false);
      alert("Coupon created successfully!");
      console.log("Create coupon", response.data);

      navigate("/coupons"); // Navigate back to the coupons list or other desired page
    } catch (err) {
      setLoading(false);
      setError("Failed to create coupon. Please try again later.");
    }
  };

  return (
    <div className="content-wrapper">
      <div className="container-fluid mt-4">
        <div className="card">
          <div className="card-header bg-info text-white">
            <h3>Create New Coupon</h3>
          </div>
          <div className="card-body">
            {error && <div className="alert alert-danger">{error}</div>}

            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Coupon Code</label>
                    <input
                      type="text"
                      className="form-control"
                      name="code"
                      value={formData.code}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label>Discount Value</label>
                    <input
                      type="number"
                      className="form-control"
                      name="discountValue"
                      value={formData.discountValue}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label>Min Order Amount</label>
                    <input
                      type="number"
                      className="form-control"
                      name="minOrderAmount"
                      value={formData.minOrderAmount}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group">
                    <label>Start Date</label>
                    <input
                      type="date"
                      className="form-control"
                      name="startDate"
                      value={formData.startDate}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label>End Date</label>
                    <input
                      type="date"
                      className="form-control"
                      name="endDate"
                      value={formData.endDate}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label>Description</label>
                    <textarea
                      className="form-control"
                      name="description"
                      value={formData.description}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group">
                    <label>Discount Type</label>
                    <select
                      className="form-control"
                      name="discountType"
                      value={formData.discountType}
                      onChange={handleChange}
                    >
                      <option value="Percentage">Percentage</option>
                      <option value="Fixed">Fixed</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <label>Max Discount Amount</label>
                    <input
                      type="number"
                      className="form-control"
                      name="maxDiscountAmount"
                      value={formData.maxDiscountAmount}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group">
                    <label>Usage Limit</label>
                    <input
                      type="number"
                      className="form-control"
                      name="usageLimit"
                      value={formData.usageLimit}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group">
                    <label>Per User Limit</label>
                    <input
                      type="number"
                      className="form-control"
                      name="perUserLimit"
                      value={formData.perUserLimit}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      name="isActive"
                      checked={formData.isActive}
                      onChange={() =>
                        setFormData({
                          ...formData,
                          isActive: !formData.isActive,
                        })
                      }
                    />
                    <label className="form-check-label">Active</label>
                  </div>
                </div>
              </div>

              <div className="form-group mt-4">
                <button
                  type="submit"
                  className="btn btn-success"
                  disabled={loading}
                >
                  {loading ? (
                    <span className="spinner-border spinner-border-sm" />
                  ) : (
                    "Create Coupon"
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateCoupon;
