import React, { useState, useEffect } from "react";
import axios from "axios";
import { BASE_URL } from "../../utils/routeNames";
import { useAuth } from "../../context/AuthContext";
import * as ROUTES from "../../utils/routeNames.js";
import { useNavigate } from "react-router-dom";

const Orders = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [auth] = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/order/allOrders`, {
          headers: {
            Authorization: `Bearer ${auth?.token}`, // Add Bearer token in headers
          },
        });

        // Sort orders by orderDate (most recent first)
        const sortedOrders = response.data.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );

        setOrders(sortedOrders);
        setLoading(false);
        console.log("Orders", sortedOrders);
      } catch (err) {
        setError("Failed to fetch orders. Please try again later.", err);
        console.log(err);

        setLoading(false);
      }
    };

    fetchOrders();
  }, [auth]);

  const handleAction = async (orderId, status) => {
    // Find the selected order
    const selectedOrder = orders.find((order) => order._id === orderId);

    if (selectedOrder) {
      const updatedOrder = { ...selectedOrder, orderStatus: status };

      try {
        // Call the API with the updated order
        await axios.patch(
          `${BASE_URL}/order/${orderId}`,
          { orderStatus: status }, // Pass only the updated field
          {
            headers: {
              Authorization: `Bearer ${auth?.token}`, // Add Bearer token in headers
            },
          }
        );

        // Update the orders state to reflect the change
        setOrders((prevOrders) =>
          prevOrders.map((order) =>
            order._id === orderId ? { ...order, orderStatus: status } : order
          )
        );

        console.log("Order successfully updated:", updatedOrder);
      } catch (error) {
        console.error("Failed to update order status:", error);
        alert("Failed to update order status. Please try again.");
      }
    } else {
      console.error("Order not found");
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="content-wrapper">
      <div className="card">
        <div className="card-header">
          <h3 className="card-title">Orders</h3>
        </div>
        <div className="card-body">
          <table className="table table-bordered table-hover">
            <thead className="bg-primary text-white">
              <tr className="text-center">
                <th>Order Status</th>
                <th>Products</th>
                <th>Vendor ID</th>
                <th>Payment Method</th>
                <th>Payment Status</th>
                <th>isOrderActive</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {orders.map((order) => (
                <tr key={order._id}>
                  <td className="text-center">{order.orderStatus || "N/A"}</td>

                  <td>
                    {order.products &&
                      order.products.map((product, index) => (
                        <div key={index}>
                          <strong>Product:</strong>{" "}
                          {product.productId?.name || "N/A"} <br />
                          <strong>Quantity:</strong> {product.quantity || "N/A"}
                        </div>
                      ))}
                  </td>
                  <td className="text-center">
                    {order.vendorId?.pocDetails?.name || "N/A"}
                  </td>
                  <td className="text-center">
                    {order.paymentMethod || "N/A"}
                  </td>
                  <td className="text-center">
                    {order.paymentStatus || "N/A"}
                  </td>

                  <td className="text-center">
                    {order.isOrderActive ? (
                      <span className="badge badge-success">Active</span>
                    ) : (
                      <span className="badge badge-danger">Inactive</span>
                    )}
                  </td>
                  <td className="text-center">
                    <button
                      className="btn btn-success btn-sm mr-2"
                      onClick={() => handleAction(order._id, "order accepted")}
                      disabled={
                        order.orderStatus === "order accepted" ||
                        order.orderStatus === "order rejected"
                      }
                    >
                      {order.orderStatus === "order accepted"
                        ? "Accepted"
                        : "Accept"}
                    </button>
                    <button
                      className="btn btn-danger btn-sm"
                      onClick={() => handleAction(order._id, "order rejected")}
                      disabled={
                        order.orderStatus === "order accepted" ||
                        order.orderStatus === "order rejected"
                      }
                    >
                      {order.orderStatus === "order rejected"
                        ? "Rejected"
                        : "Reject"}
                    </button>
                    <button
                      className="btn btn-primary btn-sm"
                      onClick={() =>
                        navigate(`${ROUTES.VIEW_ORDER}/${order._id}`)
                      }
                    >
                      View
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Orders;
