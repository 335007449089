import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { BASE_URL } from "../../utils/routeNames.js";
import { useAuth } from "../../context/AuthContext.jsx";
import { FaEye, FaTrash } from "react-icons/fa";
function ViewCategory() {
  const { id } = useParams(); // Get category ID from route params
  const [auth] = useAuth();
  const [categoryData, setCategoryData] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch category data by ID
    const fetchCategory = async () => {
      try {
        const res = await axios.get(`${BASE_URL}/category/${id}`, {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        });
        setCategoryData(res.data);
      } catch (error) {
        console.error("Error fetching category:", error);
      }
    };
    fetchCategory();
  }, [id, auth?.token]);

  if (!categoryData) {
    return <p>Loading...</p>;
  }

  const handleDelete = async () => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this category?"
    );
    if (!confirmed) return;

    try {
      await axios.delete(`${BASE_URL}/category/${id}`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      });
      navigate(-1);
    } catch (error) {
      console.error("Error deleting category:", error);
    }
  };

  return (
    <div className="content-wrapper">
      <section className="content-header">
        <h1 className="text-dark">View Category</h1>
      </section>
      <section className="content">
        <div className="row justify-content-center">
          <div className="col-md-12">
            <div className="card shadow-sm">
              <div className="card-header bg-primary text-white">
                <h3 className="card-title">Category Details</h3>
              </div>
              <div className="card-body">
                {/* Image Display */}
                <div className="row">
                  <div className="col-md-6 ">
                    {categoryData.category_image ? (
                      <img
                        src={`${BASE_URL}${categoryData.category_image}`}
                        className="img h-75 w-75 img-cover"
                        alt="Category"
                        //   style={{ width: "300px", height: "300px", objectFit: "contain", borderRadius: "8px" }}
                      />
                    ) : (
                      <img
                        src={
                          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQP1du5vEQKA0zmCd6VhkD9reaIOyhkXPrHOGCL-10oEOAh8wWSEqFx_eI&s"
                        }
                        alt="Category"
                        className="img h-75 w-75 img-cover"
                        //   style={{ width: "400px", height: "400px", objectFit: "contain", borderRadius: "8px" }}
                      />
                    )}
                  </div>

                  {/* Category Details */}
                  <div className="col-md-6">
                    <label className="font-weight-bold">Category Name:</label>
                    <p>{categoryData.category_name}</p>

                    <label className="font-weight-bold">
                      Category Description:
                    </label>
                    <p>
                      {categoryData.category_description ||
                        "No description available"}
                    </p>

                    <label className="font-weight-bold">Parent Category:</label>
                    <p>
                      {categoryData.parent_category
                        ? categoryData.parent_category.category_name
                        : "None"}
                    </p>
                  </div>
                  {/* <div className="mb-3">
                  <label className="font-weight-bold">Category Image URL:</label>
                  <p>{categoryData.category_image}</p>
                </div> */}
                </div>
              </div>
              <div className="card-footer d-flex justify-content-end">
                <button
                  className="btn btn-secondary m-1"
                  onClick={() => window.history.back()}
                >
                  Back
                </button>
                <button className="btn btn-danger m-1" onClick={handleDelete}>
                  <FaTrash /> Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default ViewCategory;
