import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { BASE_URL } from "../../utils/routeNames.js";
import { useAuth } from "../../context/AuthContext.jsx";

function CreateCategory() {
  const navigate = useNavigate();
  const [auth] = useAuth();

  const [categoryForm, setCategoryForm] = useState({
    category_name: "",
    category_description: "",
    parent_category_id: null,
    category_image: "", // Will store the image URL after upload
    is_active: true,
    commission: 0,
    commissionType: "percentage",
    createdBy: auth?.user._id,
    updatedBy: auth?.user._id,
  });

  const [errors, setErrors] = useState({});
  const [categories, setCategories] = useState([]);
  const [file, setFile] = useState(null); // State to hold the selected file
  const [imagePreview, setImagePreview] = useState(null); // State to hold the image preview

  useEffect(() => {
    fetchCategories();
  }, [auth?.token]);

  const fetchCategories = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/category/allCategory`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth?.token}`,
        },
      });
      processCategories(res.data.categories);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  const processCategories = (data) => {
    if (Array.isArray(data)) {
      const formattedCategories = data.map((category) => ({
        id: category._id,
        label: category.category_name,
      }));
      setCategories(formattedCategories);
    } else {
      console.error("Unexpected response format for categories:", data);
    }
  };

  const validate = () => {
    const newErrors = {};

    if (!categoryForm.category_name) {
      newErrors.category_name = "Category name is required.";
    }
    if (
      !categoryForm.category_description
      // ||
      // categoryForm.category_description.length < 10
    ) {
      newErrors.category_description = "Description can not be empty";
    }
    // if (isNaN(categoryForm.commission) || categoryForm.commission < 0) {
    //   newErrors.commission = "Commission must be a positive number.";
    // }
    // if (!["percentage", "flat"].includes(categoryForm.commissionType)) {
    //   newErrors.commissionType = "Invalid commission type.";
    // }
    // if (!file) {
    //   newErrors.category_image = "Category image is required.";
    // }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setCategoryForm((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSelectChange = (selectedOption) => {
    setCategoryForm((prev) => ({
      ...prev,
      parent_category_id: selectedOption.length ? selectedOption[0].id : null,
    }));
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      const reader = new FileReader();
      reader.onload = () => setImagePreview(reader.result);
      reader.readAsDataURL(selectedFile);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validate()) return;

    let imageUrl = "";

    if (file) {
      const formData = new FormData();
      formData.append("doc", file);

      try {
        const res = await axios.post(`${BASE_URL}/upload/doc`, formData, {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
            "Content-Type": "multipart/form-data",
          },
        });
        imageUrl = res.data.imageUrl;
      } catch (error) {
        console.error("Error uploading file:", error);
        return;
      }
    }

    const updatedCategoryForm = {
      ...categoryForm,
      category_image: imageUrl,
      parent_category_id: categoryForm.parent_category_id || null,
    };

    try {
      await axios.post(`${BASE_URL}/category/new`, updatedCategoryForm, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth?.token}`,
        },
      });
      navigate(-1);
    } catch (error) {
      console.error("Error creating category:", error);
    }
  };

  return (
    <div className="content-wrapper">
      <section className="content-header">
        <h1>Create Category</h1>
      </section>

      <section className="content">
        <div className="card card-primary">
          <div className="card-header">
            <h3 className="card-title">Create Category</h3>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="card-body">
              <div className="row">
                {/* Category Name */}
                <div className="col-md-12">
                  <div className="form-group">
                    <label htmlFor="category_name">Category Name</label>
                    <input
                      type="text"
                      name="category_name"
                      id="category_name"
                      value={categoryForm.category_name}
                      onChange={handleInputChange}
                      className={`form-control ${
                        errors.category_name ? "is-invalid" : ""
                      }`}
                    />
                    {errors.category_name && (
                      <div className="invalid-feedback">
                        {errors.category_name}
                      </div>
                    )}
                  </div>
                </div>

                {/* Category Description */}
                <div className="col-md-12">
                  <div className="form-group">
                    <label htmlFor="category_description">
                      Category Description
                    </label>
                    <textarea
                      name="category_description"
                      id="category_description"
                      value={categoryForm.category_description}
                      onChange={handleInputChange}
                      className={`form-control ${
                        errors.category_description ? "is-invalid" : ""
                      }`}
                    />
                    {errors.category_description && (
                      <div className="invalid-feedback">
                        {errors.category_description}
                      </div>
                    )}
                  </div>
                </div>

                {/* Parent Category */}
                {/* <div className="col-md-12">
                  <div className="form-group">
                    <label htmlFor="parent-category-typeahead">
                      Parent Category
                    </label>
                    <Typeahead
                      id="parent-category-typeahead"
                      options={categories}
                      onChange={handleSelectChange}
                      placeholder="Select a parent category"
                      selected={categories.filter(
                        (cat) => cat.id === categoryForm.parent_category_id
                      )}
                      isClearable
                    />
                  </div>
                </div> */}

                {/* Category Image */}
                {/* <div className="col-md-12">
                  <div className="form-group">
                    <label htmlFor="category_image">Category Image</label>
                    <div className="input-group">
                      <div className="custom-file">
                        <input
                          type="file"
                          className={`custom-file-input ${
                            errors.category_image ? "is-invalid" : ""
                          }`}
                          id="category_image"
                          name="category_image"
                          onChange={handleFileChange}
                          accept="image/*"
                        />
                        <label
                          className="custom-file-label"
                          htmlFor="category_image"
                        >
                          Choose file
                        </label>
                      </div>
                    </div>
                    {errors.category_image && (
                      <div className="invalid-feedback">
                        {errors.category_image}
                      </div>
                    )}
                    {imagePreview && (
                      <div className="mt-2">
                        <h6>Selected Image:</h6>
                        <img
                          src={imagePreview}
                          alt="Selected"
                          className="img-fluid img-thumbnail"
                          style={{ maxHeight: "150px", maxWidth: "150px" }}
                        />
                      </div>
                    )}
                  </div>
                </div> */}

                {/* Submit Button */}
                <div className="col-md-12">
                  <button type="submit" className="btn btn-success float-right">
                    Create Category
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </section>
    </div>
  );
}

export default CreateCategory;
