export const BASE_URL = "https://admin.goparts.co.in/api";
// export const BASE_URL = "http://localhost:3000/api";

// Auth Routes
export const LOGIN = "/login";
export const REGISTER = "/register";

// Dashboard Routes
export const ADMIN_DASHBOARD = "/admin-dashboard";
export const USER_DASHBOARD = "/user-dashboard";

// Admin Sub-Routes
export const HOME = `${ADMIN_DASHBOARD}/home`;
export const CATEGORYS = `${ADMIN_DASHBOARD}/categories`;

// CUSTOMER Management
export const NEW_CUSTOMER = `${ADMIN_DASHBOARD}/newCustomer`;
export const ALL_CUSTOMERS = `${ADMIN_DASHBOARD}/customers`;
export const VIEW_CUSTOMER = `${ADMIN_DASHBOARD}/customerDetails/:id`;
export const UPDATE_CUSTOMER = `${ADMIN_DASHBOARD}/updateCustomerDetails`;

// Product Management
export const ALL_PRODUCTS = `${ADMIN_DASHBOARD}/products`;
export const NEW_PRODUCT = `${ADMIN_DASHBOARD}/newproduct`;
export const VIEW_PRODUCT = `${ALL_PRODUCTS}/viewproduct`;
export const UPADATE_PRODUCT = `${ALL_PRODUCTS}/updateproduct`;

//Order Management
export const ALL_ORDERS = `${ADMIN_DASHBOARD}/orders`;
export const VIEW_ORDER = `${ADMIN_DASHBOARD}/vieworder`;

// MOBILE Routes
export const ALL_MOBILES = `${ADMIN_DASHBOARD}/mobiles`;
export const NEW_MOBILE = `${ADMIN_DASHBOARD}/newMobile`;
export const NEW_MODELBYBRAND = `${ADMIN_DASHBOARD}/createModels`;
export const UPDATE_MOBILE = `${ADMIN_DASHBOARD}/updateMobile`;
//Category Routes

export const NEW_CATEGORY = `${ADMIN_DASHBOARD}/newcategory`;
export const VIEW_CATEGORY = `${ADMIN_DASHBOARD}/viewcategory`;

// Proposal Routes
export const ALL_PROPOSALS = `${ADMIN_DASHBOARD}/proposals`;
export const NEW_PROPOSAL = `${ADMIN_DASHBOARD}/newProposal`;
export const UPDATE_PROPOSAL = `${ADMIN_DASHBOARD}/updateProposal`;

// Coupon
export const ALL_COUPONS = `${ADMIN_DASHBOARD}/coupons`;
export const VIEW_COUPON = `${ADMIN_DASHBOARD}/viewcoupon`;
export const NEW_COUPON = `${ADMIN_DASHBOARD}/newcoupon`;

// Client Routes (if any other than dashboard can be added here)
// e.g. export const CLIENT_PROFILE = '/user-dashboard/profile';

// Fallback/NotFound Route
export const NO_ACCESS = "/no-access";
export const NOT_FOUND = "*";
