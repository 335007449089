import React, { useState, useEffect } from "react";
import axios from "axios";
import { Typeahead } from "react-bootstrap-typeahead";
import { BASE_URL } from "../../utils/routeNames";
import "react-bootstrap-typeahead/css/Typeahead.css";
import toast from "react-hot-toast";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormGroup,
  Label,
} from "reactstrap";

const AddMobilesForProductDialog = ({
  mobiles,
  // productId,
  // productName,
  product,
  authToken,
  // initialFormData,
  loadProductDetails,
  onClose,
  showModal,
}) => {
  console.log("product : ", product);

  const [models, setModels] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  // const formattedModels = mobiles.map((model) => {
  //   // console.log("modelID is ", model._id);
  //   // console.log("brandName", model.modelId.brandId.name);
  //   // console.log("modelName", model.modelId.name);
  //   return {
  //     modelId: model._id,
  //     label: `${model.modelId.brandId.name}, ${model.modelId.name}`,
  //   };
  // });

  const [formData, setFormData] = useState({ mobile: [] });
  const [errors, setErrors] = useState({});

  // Fetch models on component mount
  useEffect(() => {
    // console.log("mobiles : ", mobiles);

    const fetchModels = async () => {
      try {
        const res = await axios.get(`${BASE_URL}/mobile/allModels`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        });

        const data = Array.isArray(res.data) ? res.data : res.data.models || [];

        if (!Array.isArray(data)) {
          throw new Error("Expected an array but received something else.");
        }

        const formattedModels = data.map((model) => ({
          id: model._id,
          label: `${model.brandId.name}, ${model.name}`,
        }));
        setModels(formattedModels);
      } catch (error) {
        console.error("Error fetching mobile models:", error);
      }
    };

    fetchModels();
  }, [authToken]);

  // Handle mobile selection
  const handleVariantChange = (selectedModels) => {
    console.log("handleVariantChange selectedModels :", selectedModels);

    setFormData((prev) => ({
      ...prev,
      mobile: selectedModels.map((model) => ({
        modelId: model.id,
        variantId: null,
      })),
    }));
  };

  // Handle form submission
  const handleSubmit = async () => {
    if (!formData.mobile.length) {
      setErrors((prev) => ({
        ...prev,
        mobile: "Please select at least one model.",
      }));
      return;
    }
    console.log("formData.mobile ", formData.mobile);
    setFormData((prev) => ({
      ...prev, // Spread the previous state
      mobile: mobiles, // Update the mobile field
    }));

    try {
      const res = await axios.patch(
        `${BASE_URL}/product/${product._id}`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      console.log("res :", res.data);

      if (res.status === 200 || res.status === 201) {
        console.log("MObile added");
        toast.success("Mobiles added");
        setErrors({});
        onClose();
        loadProductDetails();
      } else {
        console.log("Failed to add MObile");
      }
    } catch (error) {
      setErrors(error);

      onClose();
    }
  };

  return (
    <Modal
      isOpen={showModal}
      toggle={onClose}
      style={{ backgroundColor: "transparent" }} // Set the background to transparent
      backdrop={false} // Optionally prevent backdrop from closing the modal
    >
      {/*  backdrop={false}> */}
      <ModalHeader toggle={onClose}>Add Mobile for {product.name}</ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label htmlFor="mobile">Mobile</Label>
          {/* <Typeahead
            id="mobile-variants-typeahead"
            options={models}
            onChange={handleVariantChange}
            placeholder="Select mobile"
            multiple
            selected={models.filter((model) =>
              formData.mobile.some((mobile) => mobile.modelId === model.id)
            )}
            onFocus={() => setIsDropdownOpen(true)}
            onBlur={() => setIsDropdownOpen(false)} // Close dropdown on blur
            open={isDropdownOpen} // Control dropdown visibility
            clearButton
          /> */}
          <Typeahead
            id="mobile-variants-typeahead"
            options={models}
            onChange={(selected) => {
              handleVariantChange(selected); // Call your existing variant change handler
              setIsDropdownOpen(false); // Close the dropdown after selection
            }}
            placeholder="Select mobile"
            selected={models.find(
              (model) => formData.mobile?.modelId === model.id
            )} // Select only the model that matches formData.mobile.modelId
            onFocus={() => setIsDropdownOpen(true)}
            onBlur={() => setIsDropdownOpen(false)} // Close dropdown on blur
            open={isDropdownOpen} // Control dropdown visibility
            clearButton
          />

          {errors.mobile && (
            <small className="text-danger">{errors.mobile}</small>
          )}
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button color="primary" onClick={handleSubmit}>
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AddMobilesForProductDialog;
