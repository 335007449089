import React, { useState, useEffect } from "react";
import axios from "axios";
import { BASE_URL } from "../../utils/routeNames"; // Update with actual path
import { useAuth } from "../../context/AuthContext"; // Assuming you have authentication context
import * as Routes from "../../utils/routeNames";
import { useNavigate } from "react-router-dom";

const Coupons = () => {
  const [coupons, setCoupons] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [auth] = useAuth(); // Use your authentication context
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCoupons = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/coupon/coupons`, {
          headers: { Authorization: `Bearer ${auth?.token}` },
        });

        setCoupons(response.data); // Assuming the API returns an array of coupons
        setLoading(false);
      } catch (err) {
        setError("Failed to fetch coupons. Please try again later.");
        setLoading(false);
      }
    };

    fetchCoupons();
  }, [auth]);

  const handleDelete = async (couponId) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this coupon?");
    if (confirmDelete) {
      try {
        // Send DELETE request to the API to delete the coupon
        await axios.delete(`${BASE_URL}/coupon/${couponId}`, {
          headers: { Authorization: `Bearer ${auth?.token}` },
        });

        // Update the coupons list by removing the deleted coupon
        setCoupons(coupons.filter((coupon) => coupon._id !== couponId));
        alert("Coupon deleted successfully!");
      } catch (err) {
        setError("Failed to delete the coupon. Please try again later.");
      }
    }
  };

  if (loading) {
    return (
      <div className="container text-center mt-5">
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="container text-center mt-5">
        <div className="alert alert-danger">{error}</div>
      </div>
    );
  }

  return (
    <div className="content-wrapper">
      <div className="container mt-4">
        <h2 className="mb-4">Coupons List</h2>

        <div className="card">
          {/* <div className="card-header">
            <h5>Coupon Details</h5>
          </div> */}
          <div className="card-body">
          <div className="input-group mb-3 ">
              <input
                type="text"
                className="form-control"
                placeholder="Search users..."
                // value={search}
                // onChange={(e) => setSearch(e.target.value)}
              />
              <div className="input-group-append ml-3">
                <button
                  className="btn btn-success"
                    onClick={()=> navigate(`${Routes.NEW_COUPON}`)} 
                >
                  Add Coupon
                </button>
              </div>
            </div>
            <table className="table table-bordered table-hover">
              <thead className="bg-primary text-white">
                <tr className="text-center">
                  <th>Code</th>
                  <th>Description</th>
                  {/* <th>Discount Type</th> */}
                  <th>Discount Value</th>
                  <th>Min Order Amount</th>
                  <th>Start Date</th>
                  <th>End Date</th>
                  <th>Active Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {coupons.map((coupon) => (
                  <tr key={coupon._id}>
                    <td>{coupon.code}</td>
                    <td>{coupon.description || "N/A"}</td>
                    {/* <td>{coupon.discountType}</td> */}
                    <td>{coupon.discountValue}</td>
                    <td>{coupon.minOrderAmount}</td>
                    <td>{new Date(coupon.startDate).toLocaleDateString()}</td>
                    <td>{new Date(coupon.endDate).toLocaleDateString()}</td>
                    <td>
                      {coupon.isActive ? (
                        <span className="badge badge-success">Active</span>
                      ) : (
                        <span className="badge badge-danger">Inactive</span>
                      )}
                    </td>
                    <td className="text-center">
                      <button
                        className="btn btn-info btn-sm mr-2"
                        onClick={() => navigate(`${Routes.VIEW_COUPON}/${coupon._id}`)}
                      >
                        View
                      </button>
                      <button
                        className="btn btn-danger btn-sm"
                        onClick={() => handleDelete(coupon._id)}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Coupons;
