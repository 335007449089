import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { BASE_URL } from "../../utils/routeNames"; // Ensure you have the correct BASE_URL
import { useAuth } from "../../context/AuthContext";

function UserDetails() {
  const { id } = useParams(); // Get user ID from the URL params
  const navigate = useNavigate();
  const [auth] = useAuth();
  const [userForm, setUserForm] = useState({
    userType: "",
    email: "",
    name: "",
    mobileNumber: "",
    businessDetails: {
      businessName: "",
      businessAddress: {
        shopNumber: "",
        buildingName: "",
        floorNumber: "",
        streetName: "",
        landmark: "",
        area: "",
        city: "",
        state: "",
        country: "",
        pincode: "",
      },
      bankDetails: {
        bankAccountNumber: "",
        ifscCode: "",
        bankName: "",
      },
    },
    pocDetails: {
      name: "",
      mobileNumber: "",
      email: "",
    },
    preferredLanguage: "en",
    currencyPreference: "₹",
  });

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/user/${id}`, {
          headers: { Authorization: `Bearer ${auth?.token}` },
        });
        setUserForm(response.data);
        setLoading(false); // Set loading to false after data is fetched
      } catch (error) {
        console.error("Error fetching user data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, [id, auth?.token]);

  if (loading) return <div>Loading...</div>;

  return (
    <div className="content-wrapper">
      <section className="content-header">
        <h1 className="text-dark">User Details</h1>
      </section>
      <section className="content">
        <div className="row">
          {/* Point of Contact Details Section */}
          <div className="col-md-12">
            <div className="card">
              <div className="card-header bg-primary">
                <h3 className="card-title">Point of Contact (POC) Details</h3>
              </div>
              <div className="card-body">
                <div className="form-group">
                  <label>POC Name</label>
                  <p>{userForm.pocDetails.name}</p>
                </div>
                <div className="form-group">
                  <label>POC Email</label>
                  <p>{userForm.pocDetails.email}</p>
                </div>
                <div className="form-group">
                  <label>POC Mobile</label>
                  <p>{userForm.pocDetails.mobileNumber}</p>
                </div>
              </div>
            </div>
          </div>

          {/* Bank Details Section */}
          <div className="col-md-12">
            <div className="card">
              <div className="card-header bg-primary">
                <h3 className="card-title">Bank Details</h3>
              </div>
              <div className="card-body">
                <div className="form-group">
                  <label>Bank Account Number</label>
                  <p>
                    {userForm.businessDetails.bankDetails.bankAccountNumber}
                  </p>
                </div>
                <div className="form-group">
                  <label>IFSC Code</label>
                  <p>{userForm.businessDetails.bankDetails.ifscCode}</p>
                </div>
                <div className="form-group">
                  <label>Bank Name</label>
                  <p>{userForm.businessDetails.bankDetails.bankName}</p>
                </div>
              </div>
            </div>
          </div>

          {/* Business Details Section */}
          <div className="col-md-12">
            <div className="card">
              <div className="card-header bg-primary">
                <h3 className="card-title">Business Details</h3>
              </div>
              <div className="card-body">
                <div className="row">
                  {/* Business Info Section */}
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Business Name</label>
                      <p>{userForm.businessDetails.businessName}</p>
                    </div>
                    <div className="form-group">
                      <label>Shop Number</label>
                      <p>
                        {userForm.businessDetails.businessAddress.shopNumber}
                      </p>
                    </div>
                    <div className="form-group">
                      <label>Building Name</label>
                      <p>
                        {userForm.businessDetails.businessAddress.buildingName}
                      </p>
                    </div>
                    <div className="form-group">
                      <label>Floor Number</label>
                      <p>
                        {userForm.businessDetails.businessAddress.floorNumber}
                      </p>
                    </div>
                    <div className="form-group">
                      <label>Street Name</label>
                      <p>
                        {userForm.businessDetails.businessAddress.streetName}
                      </p>
                    </div>
                  </div>

                  {/* Business Address Section */}
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>City</label>
                      <p>{userForm.businessDetails.businessAddress.city}</p>
                    </div>
                    <div className="form-group">
                      <label>State</label>
                      <p>{userForm.businessDetails.businessAddress.state}</p>
                    </div>
                    <div className="form-group">
                      <label>Country</label>
                      <p>{userForm.businessDetails.businessAddress.country}</p>
                    </div>
                    <div className="form-group">
                      <label>Pincode</label>
                      <p>{userForm.businessDetails.businessAddress.pincode}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Go Back Button */}
          <div className="col-md-12">
            <button className="btn btn-secondary" onClick={() => navigate(-1)}>
              Go Back
            </button>
          </div>
        </div>
      </section>
    </div>
  );
}

export default UserDetails;
