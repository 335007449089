import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Login } from "./auth/login";
import AdminLayout from "./admin/particals/AdminLayout";
// User
import NewUser from "./admin/customers/NewUser";
import UpdateUserDetails from "./admin/customers/UpdateUser";
import Users from "./admin/customers/Users";
import UserDetails from "./admin/customers/UserDetails";
//Products
import ViewProduct from "./admin/product/ViewProduct";
import Products from "./admin/product/Products";
import NewProduct from "./admin/product/NewProduct";
// Home/ dashboard
import Home from "./admin/home/home";
//Orders
import Orders from "./admin/orders/Orders";
// Import route names
import * as Routes from "./utils/routeNames";
import UpdateProduct from "./admin/product/updateProduct";
import Category from "./admin/Category/Category";
import AccessDenied from "./components/AccessDenied";
import CreateMobile from "./admin/mobile/CreateMobile";
import CreateCategory from "./admin/Category/NewCategory";
import ViewCategory from "./admin/Category/ViewCategory";
import AllMobilesWIthVariants from "./admin/mobile/AllMobilesWIthVariants";
import CreateModelByBrand from "./admin/mobile/CreateModelByBrand";
import ViewOrder from "./admin/orders/ViewOrder";
import Coupons from "./admin/Coupon/Coupon";
import ViewCoupon from "./admin/Coupon/ViewCoupon";
import CreateCoupon from "./admin/Coupon/NewCoupon";
function App() {
  const router = createBrowserRouter([
    { path: Routes.LOGIN, element: <Login /> },

    {
      path: Routes.ADMIN_DASHBOARD,
      element: <AdminLayout />,
      children: [
        { path: Routes.HOME, element: <Home /> },
        // User
        { path: `${Routes.NEW_CUSTOMER}`, element: <NewUser /> },
        { path: Routes.ALL_CUSTOMERS, element: <Users /> },
        { path: `${Routes.VIEW_CUSTOMER}/:id`, element: <UserDetails /> },
        {
          path: `${Routes.UPDATE_CUSTOMER}/:id`,
          element: <UpdateUserDetails />,
        },
        // Category
        { path: Routes.CATEGORYS, element: <Category /> },
        { path: Routes.NEW_CATEGORY, element: <CreateCategory /> },
        { path: `${Routes.VIEW_CATEGORY}/:id`, element: <ViewCategory /> },
        //Products
        { path: Routes.NEW_PRODUCT, element: <NewProduct /> },
        { path: Routes.ALL_PRODUCTS, element: <Products /> },
        { path: `${Routes.VIEW_PRODUCT}/:id`, element: <ViewProduct /> },
        { path: `${Routes.UPADATE_PRODUCT}/:id`, element: <UpdateProduct /> },
        //Orders
        { path: Routes.ALL_ORDERS, element: <Orders /> },
        { path: `${Routes.VIEW_ORDER}/:id`, element: <ViewOrder /> },
        //Mobiles
        { path: Routes.ALL_MOBILES, element: <AllMobilesWIthVariants /> },
        { path: Routes.NEW_MODELBYBRAND, element: <CreateModelByBrand /> },
        { path: Routes.NEW_MOBILE, element: <CreateMobile /> },
        //Coupons
        { path: Routes.ALL_COUPONS, element: <Coupons /> },
        { path: `${Routes.VIEW_COUPON}/:id`, element: <ViewCoupon /> },
        { path: Routes.NEW_COUPON, element: <CreateCoupon /> },
      ],
    },
    { path: Routes.NOT_FOUND, element: <Login /> }, // Fallback for unmatched routes
    { path: Routes.NO_ACCESS, element: <AccessDenied /> }, // Fallback for unmatched routes
  ]);

  return <RouterProvider router={router} />;
}

export default App;
