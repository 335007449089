import React, { useState } from "react";
import axios from "axios";
import { useAuth } from "../../context/AuthContext";
import { BASE_URL } from "../../utils/routeNames.js";

function Home() {
  const [auth] = useAuth();
  const [moreAttachmentsToUpload, setMoreAttachmentsToUpload] = useState([]);

  // Handle file selection
  const handleFileChange = (event) => {
    const files = Array.from(event.target.files).map((file) => ({
      file,
      documentType: "", // Add documentType field for dropdown selection
    }));
    setMoreAttachmentsToUpload(files);
  };

  // Handle document type change for each file
  const handleDocumentTypeChange = (index, type) => {
    setMoreAttachmentsToUpload((prevAttachments) =>
      prevAttachments.map((attachment, idx) =>
        idx === index ? { ...attachment, documentType: type } : attachment
      )
    );
  };

  // Upload files
  const handleUploadFiles = async () => {
    const formData = new FormData();
    moreAttachmentsToUpload.forEach((attachment) => {
      formData.append("docs", attachment.file);
    });

    try {
      const response = await axios.post(`${BASE_URL}/upload/docs`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${auth.token}`,
        },
      });
      response.data.files.map((file) => {
        const newAttachment = {
          filename: file.filename,
          path: `${BASE_URL}${file.url}`,
        };
        // proposalData.attachments.push(newAttachment);
      });
      return true;
    } catch (error) {
      console.error("Error uploading files:", error);
      return false;
    }
  };

  return (
    <div className="content-wrapper">
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-3 col-6">
              <div className="small-box bg-info">
                <div className="inner">
                  <h3>150</h3>
                  <p>Products</p>
                </div>
                <div className="icon">
                  <i className="fas fa-box"></i>
                </div>
                <a href="/products" className="small-box-footer">
                  More info <i className="fas fa-arrow-circle-right"></i>
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-6">
              <div className="small-box bg-success">
                <div className="inner">
                  <h3>
                    53<sup style={{ fontSize: "20px" }}>%</sup>
                  </h3>
                  <p>Orders Completed</p>
                </div>
                <div className="icon">
                  <i className="fas fa-shopping-cart"></i>
                </div>
                <a href="/orders" className="small-box-footer">
                  More info <i className="fas fa-arrow-circle-right"></i>
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-6">
              <div className="small-box bg-warning">
                <div className="inner">
                  <h3>44</h3>
                  <p>Users</p>
                </div>
                <div className="icon">
                  <i className="fas fa-users"></i>
                </div>
                <a href="/users" className="small-box-footer">
                  More info <i className="fas fa-arrow-circle-right"></i>
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-6">
              <div className="small-box bg-danger">
                <div className="inner">
                  <h3>65</h3>
                  <p>Reports</p>
                </div>
                <div className="icon">
                  <i className="fas fa-chart-line"></i>
                </div>
                <a href="/reports" className="small-box-footer">
                  More info <i className="fas fa-arrow-circle-right"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Home;
