import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { BASE_URL } from "../../utils/routeNames"; // Ensure you have the correct BASE_URL
import { useAuth } from "../../context/AuthContext";

function UpdateUser() {
  const { id } = useParams(); // Get user ID from the URL params
  const navigate = useNavigate();
  const [auth] = useAuth();
  const [userForm, setUserForm] = useState({
    userType: "",
    email: "",
    name: "",
    mobileNumber: "",
    password: "123456",
    otp: { code: "", expiresAt: "" },
    isActive: true,
    isBanned: false,
    businessDetails: {
      businessName: "",
      businessAddress: {
        shopNumber: "",
        buildingName: "",
        floorNumber: "", // New floorNumber field
        streetName: "",
        landmark: "",
        area: "",
        city: "",
        state: "",
        country: "",
        pincode: "",
      },
      bankDetails: {
        bankAccountNumber: "",
        ifscCode: "",
        bankName: "",
      },
      operationalHours: { open: "09:00 AM", close: "06:00 PM" },
      mapCoordinates: { latitude: 12.9716, longitude: 77.5946 },
      businessPlacePhotos: [],
      businessType: "Proprietor",
      businessCategory: "Retail",
      uploadBusinessDocs: [],
      rating: 0,
    },
    pocDetails: {
      name: "",
      mobileNumber: "",
      email: "",
    },
    preferredPaymentMethod: "UPI",
    orderHistory: [],
    permissions: {
      canManageVendors: false,
      canManageOrders: false,
      canManageCustomers: false,
    },
    activityLog: [],
    resetPasswordToken: null,
    resetPasswordExpires: null,
    emailVerified: true,
    phoneVerified: true,
    notificationSettings: {
      email: true,
      sms: true,
      appPush: false,
    },
    preferredLanguage: "en",
    currencyPreference: "₹",
  });

  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState({});

  // Fetch the user data
  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("token"); // Assuming token is stored in localStorage

        const response = await axios.get(`${BASE_URL}/user/${id}`, {
          headers: { Authorization: `Bearer ${auth?.token}` },
        });
        setUserForm(response.data);
        console.log("Single User", response.data);

        setLoading(false); // Set loading to false after data is fetched
      } catch (error) {
        console.error("Error fetching user data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  // Handle form input changes
  const handleInputChange = (e, section, subsection) => {
    const { name, value } = e.target;
    setUserForm((prev) => {
      if (subsection) {
        return {
          ...prev,
          [section]: {
            ...prev[section],
            [subsection]: { ...prev[section][subsection], [name]: value },
          },
        };
      } else if (section) {
        return { ...prev, [section]: { ...prev[section], [name]: value } };
      } else {
        return { ...prev, [name]: value };
      }
    });
  };

  // Handle form submission to update user
  const handleUpdate = async () => {
    try {
      const response = await axios.put(`${BASE_URL}/user/${id}`, userForm, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      });
      console.log("User updated successfully:", response.data);
      navigate(-1);
    } catch (error) {
      console.error("Error updating user:", error);
    }
  };

  if (loading) return <div>Loading...</div>;

  return (
    <div className="content-wrapper">
      <section className="content-header">
        <h1 className="text-dark">Update User</h1>
      </section>
      <section className="content">
        <div className="row">
          {/* Point of Contact Details Section */}
          <div className="col-md-12">
            <div className="card">
              <div className="card-header bg-info text-white">
                <h3 className="card-title">Point of Contact (POC) Details</h3>
              </div>
              <div className="card-body">
                <div className="form-group">
                  <label>POC Name</label>
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    value={userForm.pocDetails.name}
                    onChange={(e) => handleInputChange(e, "pocDetails")}
                  />
                </div>
                <div className="form-group">
                  <label>POC Email</label>
                  <input
                    type="email"
                    className="form-control"
                    name="email"
                    value={userForm.pocDetails.email}
                    onChange={(e) => handleInputChange(e, "pocDetails")}
                    readOnly
                  />
                </div>
                <div className="form-group">
                  <label>POC Mobile</label>
                  <input
                    type="text"
                    className="form-control"
                    name="mobileNumber"
                    value={userForm.pocDetails.mobileNumber}
                    onChange={(e) => handleInputChange(e, "pocDetails")}
                    readOnly
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Bank Details Section */}
          <div className="col-md-12">
            <div className="card">
              <div className="card-header bg-info text-white">
                <h3 className="card-title">Bank Details</h3>
              </div>
              <div className="card-body">
                <div className="form-group">
                  <label>Bank Account Number</label>
                  <input
                    type="text"
                    className="form-control"
                    name="bankAccountNumber"
                    value={
                      userForm.businessDetails.bankDetails.bankAccountNumber
                    }
                    onChange={(e) => handleInputChange(e, "bankDetails")}
                  />
                </div>
                <div className="form-group">
                  <label>IFSC Code</label>
                  <input
                    type="text"
                    className="form-control"
                    name="ifscCode"
                    value={userForm.businessDetails.bankDetails.ifscCode}
                    onChange={(e) => handleInputChange(e, "bankDetails")}
                  />
                </div>
                <div className="form-group">
                  <label>Bank Name</label>
                  <input
                    type="text"
                    className="form-control"
                    name="bankName"
                    value={userForm.businessDetails.bankDetails.bankName}
                    onChange={(e) => handleInputChange(e, "bankDetails")}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Business Details Section */}
          <div className="col-md-12">
            <div className="card">
              <div className="card-header bg-info text-white">
                <h3 className="card-title">Business Details</h3>
              </div>
              <div className="card-body">
                <div className="row">
                  {/* Business Info Section */}
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Business Name</label>
                      <input
                        type="text"
                        className="form-control"
                        name="businessName"
                        value={userForm.businessDetails.businessName}
                        onChange={(e) =>
                          handleInputChange(e, "businessDetails")
                        }
                      />
                    </div>
                    <div className="form-group">
                      <label>Shop Number</label>
                      <input
                        type="text"
                        className="form-control"
                        name="shopNumber"
                        value={
                          userForm.businessDetails.businessAddress.shopNumber
                        }
                        onChange={(e) =>
                          handleInputChange(
                            e,
                            "businessDetails",
                            "businessAddress"
                          )
                        }
                      />
                    </div>
                    <div className="form-group">
                      <label>Building Name</label>
                      <input
                        type="text"
                        className="form-control"
                        name="buildingName"
                        value={
                          userForm.businessDetails.businessAddress.buildingName
                        }
                        onChange={(e) =>
                          handleInputChange(
                            e,
                            "businessDetails",
                            "businessAddress"
                          )
                        }
                      />
                    </div>
                    <div className="form-group">
                      <label>Floor Number</label> {/* New floorNumber input */}
                      <input
                        type="text"
                        className="form-control"
                        name="floorNumber"
                        value={
                          userForm.businessDetails.businessAddress.floorNumber
                        }
                        onChange={(e) =>
                          handleInputChange(
                            e,
                            "businessDetails",
                            "businessAddress"
                          )
                        }
                      />
                    </div>
                    <div className="form-group">
                      <label>Street Name</label>
                      <input
                        type="text"
                        className="form-control"
                        name="streetName"
                        value={
                          userForm.businessDetails.businessAddress.streetName
                        }
                        onChange={(e) =>
                          handleInputChange(
                            e,
                            "businessDetails",
                            "businessAddress"
                          )
                        }
                      />
                    </div>
                  </div>

                  {/* Business Address Section */}
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>City</label>
                      <input
                        type="text"
                        className="form-control"
                        name="city"
                        value={userForm.businessDetails.businessAddress.city}
                        onChange={(e) =>
                          handleInputChange(
                            e,
                            "businessDetails",
                            "businessAddress"
                          )
                        }
                      />
                    </div>
                    <div className="form-group">
                      <label>State</label>
                      <input
                        type="text"
                        className="form-control"
                        name="state"
                        value={userForm.businessDetails.businessAddress.state}
                        onChange={(e) =>
                          handleInputChange(
                            e,
                            "businessDetails",
                            "businessAddress"
                          )
                        }
                      />
                    </div>
                    <div className="form-group">
                      <label>Country</label>
                      <input
                        type="text"
                        className="form-control"
                        name="country"
                        value={userForm.businessDetails.businessAddress.country}
                        onChange={(e) =>
                          handleInputChange(
                            e,
                            "businessDetails",
                            "businessAddress"
                          )
                        }
                      />
                    </div>
                    <div className="form-group">
                      <label>Pincode</label>
                      <input
                        type="text"
                        className="form-control"
                        name="pincode"
                        value={userForm.businessDetails.businessAddress.pincode}
                        onChange={(e) =>
                          handleInputChange(
                            e,
                            "businessDetails",
                            "businessAddress"
                          )
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Submit Button */}
          <div className="col-md-12">
            <button className="btn btn-primary" onClick={handleUpdate}>
              Update User
            </button>
          </div>
        </div>
      </section>
    </div>
  );
}

export default UpdateUser;
