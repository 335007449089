import React, { useEffect, useState } from "react";
import axios from "axios";
import { BASE_URL } from "../../utils/routeNames";
import { useNavigate } from "react-router-dom";

import { useAuth } from "../../context/AuthContext";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import * as ROUTES from "../../utils/routeNames.js";

const AllMobilesWIthVariants = () => {
  const navigate = useNavigate();
  const [variants, setVariants] = useState([]);
  const [auth] = useAuth();
  const fetchVariants = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/mobile/models`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      });
      console.log(response.data);

      return response.data; // Assuming the API returns the array of variants.
    } catch (error) {
      console.error("Error fetching variants:", error);
      return [];
    }
  };
  useEffect(() => {
    const loadVariants = async () => {
      const data = await fetchVariants();
      setVariants(data);
      console.log("models are ", data);
    };
    loadVariants();
  }, []);

  const toggleNewMobile = () => {
    navigate(`${ROUTES.NEW_MOBILE}`);
    // setIsNewUserFormOpen((prev) => !prev);
  };

  const toggleModelsByBrand = () => {
    navigate(`${ROUTES.NEW_MODELBYBRAND}`);
    // setIsNewUserFormOpen((prev) => !prev);
  };
  const [expandedBrand, setExpandedBrand] = useState(null);

  const toggleExpand = (brandId) => {
    setExpandedBrand(expandedBrand === brandId ? null : brandId);
  };

  return (
    <div className="content-wrapper">
      {/* Content Header */}
      <section className="content-header">
        <div className="container-fluid">
          <div className="row align-items-center justify-content-between my-3">
            {/* Title */}
            <div className="col-12 col-md-4 mb-2 mb-md-0">
              <h1 className="font-weight-bold">Mobiles</h1>
            </div>

            {/* Buttons */}
            <div className="col-12 col-md-8 d-flex flex-column flex-md-row justify-content-md-end">
              <button
                onClick={toggleModelsByBrand}
                className="btn btn-primary mt-2  mt-md-0 me-md-2"
              >
                <i className="fas fa-plus me-1"></i> Add ModelsByBrand
              </button>
              <button
                onClick={toggleNewMobile}
                className="btn btn-danger mr-2 mt-2 mt-md-0"
              >
                <i className="fas fa-plus me-1"></i> Add Mobile
              </button>
            </div>
          </div>
        </div>
      </section>

      <table
        border="1"
        cellPadding="10"
        style={{ width: "100%", textAlign: "left" }}
      >
        <thead>
          <tr>
            <th>Brand Logo</th>
            <th>Brand Name</th>
            <th>Models</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(variants).map((brandKey) => {
            const brandData = variants[brandKey].brand;
            const models = variants[brandKey].models;

            return (
              // <React.Fragment key={brandKey}>
              //   <tr>
              //     <td>
              //       <img
              //         src={brandData.brandImage}
              //         alt={`${brandData.name} logo`}
              //         style={{ width: "50px", height: "auto" }}
              //       />
              //     </td>
              //     <td>{brandData.name}</td>
              //     <td>
              //       <button onClick={() => toggleExpand(brandKey)}>
              //         {expandedBrand === brandKey
              //           ? "Hide Models"
              //           : "Show Models"}
              //       </button>
              //     </td>
              //   </tr>
              //   {expandedBrand === brandKey && (

              //     <tr>
              //       <td colSpan="3">
              //         <div
              //           style={{
              //             display: "grid",
              //             gridTemplateColumns: "repeat(3, 1fr)",
              //             gap: "10px",
              //             padding: "10px",
              //             border: "1px solid #ccc",
              //           }}
              //         >
              //           {models.map((model) => (
              //             <div
              //               key={model._id}
              //               style={{
              //                 border: "1px solid #ccc",
              //                 padding: "10px",
              //                 borderRadius: "5px",
              //                 textAlign: "center",
              //               }}
              //             >
              //               <strong>{model.name}</strong>
              //             </div>
              //           ))}
              //         </div>
              //       </td>
              //     </tr>
              //   )}
              // </React.Fragment>
              <React.Fragment key={brandKey}>
                <tr className="text-center align-middle">
                  <td>
                    <img
                      src={`${BASE_URL.replace("/api", "")}${
                        brandData.brandImage
                      }`}
                      alt={`${brandData.name} logo`}
                      className="img-thumbnail border border-primary"
                      style={{ width: "50px", height: "auto" }}
                    />
                  </td>
                  <td className="text-bold text-dark">{brandData.name}</td>
                  <td>
                    <button
                      onClick={() => toggleExpand(brandKey)}
                      className={`btn btn-sm ${
                        expandedBrand === brandKey ? "btn-danger" : "btn-info"
                      } rounded-pill`}
                    >
                      {expandedBrand === brandKey
                        ? "Hide Models"
                        : "Show Models"}
                    </button>
                  </td>
                </tr>

                {expandedBrand === brandKey && (
                  <tr>
                    <td colSpan="3">
                      <div className="card">
                        <div className="card-body">
                          <div className="row">
                            {models.map((model) => (
                              <div
                                key={model._id}
                                className="col-lg-4 col-md-6 col-sm-12 mb-4"
                              >
                                <div className="card border border-dark shadow-sm ">
                                  <div className="card-body text-center">
                                    <strong>{model.name}</strong>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default AllMobilesWIthVariants;
