import axios from "axios";
import React, { useEffect, useState } from "react";
import { useAuth } from "../../context/AuthContext";
import * as ROUTES from "../../utils/routeNames.js";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../../utils/routeNames.js";
// import { FaEye, FaTrash } from "react-icons/fa";
import toast from "react-hot-toast";
function Category() {
  const [auth] = useAuth();
  const [categoryNameList, setCategoryNameList] = useState([]);
  const [isCategoryLoading, setLoading] = useState(true);
  const [value, setValue] = useState("");
  const [editCategory, setEditCategory] = useState(null); // For editing category
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(32);
  const [totalPages, setTotalPages] = useState(0);
  const navigate = useNavigate();

  const fetchCategories = async () => {
    setLoading(true);
    try {
      const res = await axios.get(
        `${BASE_URL}/category/allCategory?page=${currentPage}&limit=${itemsPerPage}&search=${searchQuery}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${auth?.token}`,
          },
        }
      );
      setLoading(false);
      setCategoryNameList(res.data.categories);
      setTotalPages(res.data.totalPages);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  const openAddCategoryModal = () => {
    navigate(ROUTES.NEW_CATEGORY);
  };

  const handleSearchChange = (e) => {
    setValue(e.target.value);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage((prev) => prev + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage((prev) => prev - 1);
  };

  const openEditCategoryModal = (category) => {
    setEditCategory(category);
  };

  const closeEditCategoryModal = () => {
    setEditCategory(null);
  };

  const handleEditCategory = async (updatedCategory) => {
    try {
      const res = await axios.patch(
        `${BASE_URL}/category/${updatedCategory._id}`,
        { category_name: updatedCategory.category_name },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${auth?.token}`,
          },
        }
      );
      if (res.status === 200) {
        toast.success("Category Updated");
        fetchCategories();
        closeEditCategoryModal();
      }
    } catch (error) {
      toast.error("Failed to update Category");
      console.error("Error updating category:", error);
    }
  };

  useEffect(() => {
    if (auth?.token) fetchCategories();
  }, [auth, currentPage, searchQuery]);

  return (
    <div className="content-wrapper">
      <section className="content-header">
        <div className="container-fluid">
          <div className="row align-items-center justify-content-between my-3">
            <div className="col-md-4">
              <h1 className="text-left">Categories</h1>
            </div>
            <div className="col-md-8 d-flex justify-content-end">
              <div className="form-group mb-0 flex-grow-1 mr-3">
                <div className="input-group input-group-md">
                  <input
                    type="search"
                    value={value}
                    onKeyDown={(e) => {
                      setCurrentPage(1);
                      if (e.key === "Enter") setSearchQuery(value);
                    }}
                    onChange={handleSearchChange}
                    className="form-control form-control-md"
                    placeholder="Search..."
                  />
                </div>
              </div>
              <button
                className="btn btn-success"
                onClick={openAddCategoryModal}
              >
                Add Category
              </button>
            </div>
          </div>
        </div>
      </section>
      <section className="content">
        <div className="row">
          {!isCategoryLoading && categoryNameList?.length ? (
            categoryNameList.map((category) => (
              <div
                key={category._id}
                className="col-lg-2 col-md-3 col-sm-4 col-6  d-flex justify-content-center"
              >
                <div className="card shadow-sm" style={{ width: "15rem" }}>
                  <div
                    className="card-body"
                    onClick={() => openEditCategoryModal(category)}
                  >
                    <h6>{category.category_name}</h6>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p className="text-center">No Data Found</p>
          )}
        </div>

        {totalPages > 1 && (
          <div className="pagination m-2 d-flex justify-content-center">
            <button
              className="btn btn-primary m-1"
              onClick={handlePrevPage}
              disabled={currentPage === 1}
            >
              Previous
            </button>
            {Array.from({ length: totalPages }, (_, index) => (
              <button
                key={`page-${index + 1}`}
                onClick={() => setCurrentPage(index + 1)}
                className={`btn m-1 ${
                  currentPage === index + 1 ? "bg-primary" : "btn-light"
                }`}
              >
                {index + 1}
              </button>
            ))}
            <button
              className="btn btn-primary m-1"
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
            >
              Next
            </button>
          </div>
        )}
      </section>

      {/* Edit Category Modal */}
      {editCategory && (
        <EditCategoryModal
          category={editCategory}
          onClose={closeEditCategoryModal}
          onSubmit={handleEditCategory}
        />
      )}
    </div>
  );
}

function EditCategoryModal({ category, onClose, onSubmit }) {
  const [categoryName, setCategoryName] = useState(category.category_name);

  const handleSave = () => {
    onSubmit({ ...category, category_name: categoryName });
  };

  return (
    <div className="modal show d-block" tabIndex="-1" role="dialog">
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Edit Category</h5>
            <button
              type="button"
              className="close"
              onClick={onClose}
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <input
              type="text"
              className="form-control"
              value={categoryName}
              onChange={(e) => setCategoryName(e.target.value)}
              placeholder="Category Name"
            />
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={onClose}
            >
              Close
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleSave}
            >
              Save changes
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Category;
