import axios from "axios";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input,
} from "reactstrap";

import React, { useEffect, useState } from "react";
import { useAuth } from "../../context/AuthContext.jsx";
// import { useUserContext } from "../../context/UserContext.jsx";
import { useNavigate } from "react-router-dom";
import * as ROUTES from "../../utils/routeNames.js";
import { BASE_URL } from "../../utils/routeNames.js";
const Users = () => {
  const [auth] = useAuth();
  const [selectedUser, setSelectedUser] = useState(null); // For modal details
  const [selectedUserForEdit, setSelectedUserForEdit] = useState(null); // For modal details
  const [formData, setFormData] = useState({});
  const navigate = useNavigate();
  const handleUserDetails = (user) => {
    navigate(`${ROUTES.VIEW_CUSTOMER}/${user._id}`);
    // setSelectedUser(user);
  };

  // Opens the edit modal with the selected user's data
  const handleEditUserDetails = (user) => {
    console.log("Update", user);

    navigate(`${ROUTES.UPDATE_CUSTOMER}/${user._id}`);
    setSelectedUserForEdit(user);
  };

  // Closes the modal and clears the selected user for edit
  const handleCloseModal = () => {
    setSelectedUserForEdit(null);
  };

  // Updates the selected user data based on field changes
  const handleInputChange = (field, value) => {
    setSelectedUserForEdit((prev) => {
      const updatedUser = { ...prev };
      const keys = field.split(".");

      let ref = updatedUser;
      for (let i = 0; i < keys.length - 1; i++) {
        ref = ref[keys[i]];
      }

      ref[keys[keys.length - 1]] = value;
      return updatedUser;
    });
  };

  const handleEditUser = async (e) => {
    e.preventDefault();

    try {
      // Merge selectedUser with updated fields in formData
      const updatedUserData = { ...selectedUserForEdit, ...formData };
      console.log(updatedUserData);

      // Replace 'selectedUserForEdit._id' with the correct user ID field
      const response = await axios.put(
        ROUTES.BASE_URL + `/user/${selectedUserForEdit._id}`,
        updatedUserData,
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        }
      );

      if (response.status === 200) {
        alert("User updated successfully");
        handleCloseModal(); // Close modal after successful update
        fetchUsers(); // Optionally refresh users list
      }
    } catch (error) {
      // console.error("Failed to update user:", error);
      setAlertMessage(`Failed to update user. Please try again. \n ${error}`);
      setShowAlert(true);
      // setAlert({
      //   type: "danger",
      //   message: "Failed to update user. Please try again.",
      // });

      // alert(`Failed to update user. Please try again. \n ${error}`);
    }
  };
  const [isNewUserFormOpen, setIsNewUserFormOpen] = useState(false);
  // const [formData, setFormData] = useState(null);

  // Toggle function to open/close the modal
  const toggleNewUserForm = (cusId) => {
    navigate(`${ROUTES.NEW_CUSTOMER}`);
    // setIsNewUserFormOpen((prev) => !prev);
  };

  // Handle saving the form data
  const handleSaveUser = (data) => {
    console.log("User data saved:", data);
    setFormData(data);
    toggleNewUserForm(); // Close the modal after saving
  };

  {
    /*Variable */
  }
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [userType, setUserType] = useState("Customer");
  const [search, setSearch] = useState("");
  const [totalUsers, setTotalUsers] = useState(0);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  {
    /**Methodes */
  }
  const handleCloseAlert = () => {
    setShowAlert(false);
  };
  const fetchUsers = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/user/users`, {
        params: { page, limit, userType, search },
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      });
      setUsers(response.data.users ?? []);
      setTotalUsers(response.data.totalUsers);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, [page, limit, userType, search]);

  {
    /**UI */
  }
  return (
    <div className="content-wrapper">
      <div className="container-fluid">
        {/* {alert && (
          <div
            className={`alert alert-${alert.type} alert-dismissible fade show`}
            role="alert"
          >
            {alert.message}
            <button
              type="button"
              className="close"
              aria-label="Close"
              onClick={handleCloseAlert}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        )} */}
        <div className="card">
          <div className="card-header">
            <h3 className="card-title">User Management</h3>
          </div>

          <div className="card-body">
            {/* Search Bar and Add New Customer Button */}
            <div className="input-group mb-3 ">
              <input
                type="text"
                className="form-control"
                placeholder="Search users..."
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              <div className="input-group-append ml-3">
                <button
                  className="btn btn-success"
                  onClick={toggleNewUserForm} // Replace with your actual handler
                >
                  Add New Customer
                </button>
              </div>
            </div>

            {/* Users Table */}
            <table className="table table-bordered table-striped">
              <thead>
                <tr>
                  <th>User Type</th>
                  <th>Business Name</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Mobile Number</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {users?.length > 0 ? (
                  users.map((user) => (
                    <tr key={user._id}>
                      <td>{user.userType}</td>
                      <td>{user.businessDetails?.businessName || "N/A"}</td>
                      <td>{user.pocDetails?.name || "N/A"}</td>
                      <td>{user.pocDetails?.email || "N/A"}</td>
                      <td>{user.pocDetails?.mobileNumber || "N/A"}</td>
                      <td>
                        <button
                          className="btn btn-primary btn-sm"
                          onClick={() => handleUserDetails(user)}
                          data-toggle="modal"
                          data-target="#userModal"
                        >
                          <i className="fas fa-eye mr-1"></i>
                        </button>

                        <button
                          className="btn btn-warning btn-sm ml-2" // Use 'ml-2' for left margin
                          onClick={() => handleEditUserDetails(user)}
                          data-toggle="modal"
                          data-target="#editUserModal" // Ensure this targets the correct modal
                        >
                          <i className="fas fa-edit mr-1"></i>{" "}
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="5" className="text-center">
                      No users found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>

            {/* Pagination */}
            <div className="d-flex justify-content-between align-items-center mt-3">
              <button
                className="btn btn-secondary"
                disabled={page === 1}
                onClick={() => setPage((prev) => Math.max(prev - 1, 1))}
              >
                Previous
              </button>
              <span>
                Page {page} of {Math.ceil(totalUsers / limit)}
              </span>
              <button
                className="btn btn-secondary"
                disabled={page * limit >= totalUsers}
                onClick={() => setPage((prev) => prev + 1)}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* User Details Modal */}
      {selectedUser && (
        <div
          className="modal fade bd-example-modal-xl"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myExtraLargeModalLabel"
          aria-hidden="true"
          id="userModal"
        >
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
              <div className="modal-header bg-primary">
                <h5 className="modal-title text-white">
                  <i className="fas fa-user mr-2"></i>User Details
                </h5>
                <button
                  type="button"
                  className="close text-white"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={handleCloseModal}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="container-fluid">
                  {/* User Type */}
                  <div className="row mb-3">
                    <div className="col-md-4">
                      <span className="font-weight-bold">User Type:</span>
                    </div>
                    <div className="col-md-8">
                      <span>{selectedUser.userType || "N/A"}</span>
                    </div>
                  </div>

                  {/* Business Information */}
                  <div className="card card-outline card-primary mb-3">
                    <div className="card-header">
                      <h5 className="card-title">
                        <i className="fas fa-building mr-2"></i>Business Details
                      </h5>
                    </div>
                    <div className="card-body">
                      <p>
                        <strong>Business Name:</strong>{" "}
                        {selectedUser.businessDetails?.businessName || "N/A"}
                      </p>
                      <strong>Business Address:</strong>
                      <ul className="list-unstyled ml-3 mt-2">
                        {selectedUser.businessDetails?.businessAddress
                          ? Object.entries(
                              selectedUser.businessDetails.businessAddress
                            ).map(([key, value]) => (
                              <li key={key}>
                                <strong>
                                  {key.replace(/([A-Z])/g, " $1")}:{" "}
                                </strong>
                                {value || "N/A"}
                              </li>
                            ))
                          : "N/A"}
                      </ul>
                    </div>
                  </div>

                  {/* POC Details */}
                  <div className="card card-outline card-info mb-3">
                    <div className="card-header">
                      <h5 className="card-title">
                        <i className="fas fa-id-card mr-2"></i>POC Details
                      </h5>
                    </div>
                    <div className="card-body">
                      <p>
                        <strong>POC Name:</strong>{" "}
                        {selectedUser.pocDetails?.name || "N/A"}
                      </p>
                      <p>
                        <strong>POC Email:</strong>{" "}
                        {selectedUser.pocDetails?.email || "N/A"}
                      </p>
                      <p>
                        <strong>Mobile Number:</strong>{" "}
                        {selectedUser.pocDetails?.mobileNumber || "N/A"}
                      </p>
                    </div>
                  </div>

                  {/* Add additional sections as needed */}
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  onClick={handleCloseModal}
                >
                  <i className="fas fa-times mr-1"></i> Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Update User details Modal */}
      {selectedUserForEdit && (
        <div
          className="modal fade bd-example-modal-xl"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myExtraLargeModalLabel"
          aria-hidden="true"
          id="editUserModal"
        >
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="modal-header bg-warning">
                <h5 className="modal-title text-white">
                  <i className="fas fa-edit mr-2"></i>Edit User Details
                </h5>
                <button
                  type="button"
                  className="close text-white"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={handleCloseModal}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <form onSubmit={handleEditUser}>
                  <div className="container-fluid">
                    {/* User Type */}
                    <div className="form-group row">
                      <label className="col-sm-3 col-form-label font-weight-bold">
                        User Type
                      </label>
                      <div className="col-sm-9">
                        <p>{selectedUserForEdit.userType || ""}</p>
                      </div>
                    </div>

                    {/* Business Details */}
                    <div className="card card-outline card-primary mb-3">
                      <div className="card-header">
                        <h5 className="card-title">
                          <i className="fas fa-building mr-2"></i>Business
                          Details
                        </h5>
                      </div>
                      <div className="card-body">
                        <div className="form-group">
                          <label>Business Name</label>
                          <input
                            type="text"
                            className="form-control"
                            value={
                              selectedUserForEdit.businessDetails
                                ?.businessName || ""
                            }
                            onChange={(e) =>
                              handleInputChange(
                                "businessDetails.businessName",
                                e.target.value
                              )
                            }
                          />
                        </div>

                        <label>Business Address</label>
                        {selectedUserForEdit.businessDetails
                          ?.businessAddress && (
                          <div className="row">
                            {Object.entries(
                              selectedUserForEdit.businessDetails
                                .businessAddress
                            ).map(([key, value]) => (
                              <div className="form-group col-md-6" key={key}>
                                <label>
                                  {key
                                    .replace(/([A-Z])/g, " $1")
                                    .charAt(0)
                                    .toUpperCase() +
                                    key.replace(/([A-Z])/g, " $1").slice(1)}
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={value || ""}
                                  onChange={(e) =>
                                    handleInputChange(
                                      `businessDetails.businessAddress.${key}`,
                                      e.target.value
                                    )
                                  }
                                />
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>

                    {/* POC Details */}
                    <div className="card card-outline card-info mb-3">
                      <div className="card-header">
                        <h5 className="card-title">
                          <i className="fas fa-id-card mr-2"></i>POC Details
                        </h5>
                      </div>
                      <div className="card-body">
                        <div className="form-group">
                          <label>POC Name</label>
                          <input
                            type="text"
                            className="form-control"
                            value={selectedUserForEdit.pocDetails?.name || ""}
                            onChange={(e) =>
                              handleInputChange(
                                "pocDetails.name",
                                e.target.value
                              )
                            }
                          />
                        </div>
                        <div className="form-group">
                          <label>POC Email</label>
                          <input
                            type="email"
                            className="form-control"
                            value={selectedUserForEdit.pocDetails?.email || ""}
                            onChange={(e) =>
                              handleInputChange(
                                "pocDetails.email",
                                e.target.value
                              )
                            }
                          />
                        </div>
                        <div className="form-group">
                          <label>Mobile Number</label>
                          <input
                            type="text"
                            className="form-control"
                            value={
                              selectedUserForEdit.pocDetails?.mobileNumber || ""
                            }
                            onChange={(e) =>
                              handleInputChange(
                                "pocDetails.mobileNumber",
                                e.target.value
                              )
                            }
                          />
                        </div>
                      </div>
                    </div>

                    {/* Additional fields */}
                    {/* Add other fields following similar pattern */}
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  onClick={handleCloseModal}
                >
                  <i className="fas fa-times mr-1"></i> Close
                </button>
                <button
                  type="submit"
                  className="btn btn-success"
                  onClick={handleEditUser}
                >
                  <i className="fas fa-save mr-1"></i> Save Changes
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* User Form Modal */}
      <NewUserForm
        isOpen={isNewUserFormOpen}
        toggle={toggleNewUserForm}
        onSave={handleSaveUser}
      />
    </div>
  );
};

export default Users;

const NewUserForm = ({ isOpen, toggle, onSave }) => {
  const [formData, setFormData] = useState({
    userType: "", // New field
    userName: "", // New field
    mobileNumber: "", // New field
    password: "", // New field
    otp: "", // New field
    businessDetails: {
      businessName: "",
      businessType: "",
      businessCategory: "",
      uploadBusinessDocs: [],
      businessAddress: {
        shopNumber: "",
        buildingName: "",
        floorNumber: "",
        streetName: "",
        landmark: "",
        area: "",
        city: "",
        state: "",
        country: "",
        pincode: "",
      },
      mapCoordinates: {
        latitude: "",
        longitude: "",
      },
      businessPlacePhotos: [],
    },

    pocDetails: {
      name: "",
      mobileNumber: "",
      email: "",
    },
    personalDocs: [], // New field
    driverDetails: {
      driverName: "", // Nested field under driverDetails
      photo: "", // Nested field under driverDetails
    },
  });

  // Handle input change dynamically for nested fields
  const handleInputChange = (fieldPath, value) => {
    setFormData((prevData) => {
      const fields = fieldPath.split(".");
      const updatedData = { ...prevData };

      fields.reduce((acc, key, index) => {
        if (index === fields.length - 1) {
          acc[key] = value;
        } else {
          acc[key] = { ...acc[key] }; // Create nested object if not existing
        }
        return acc[key];
      }, updatedData);

      return updatedData;
    });
  };

  const handleSubmit = () => {
    onSave(formData);
    toggle();
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Edit User Details</ModalHeader>
      <ModalBody>
        {/* New fields added below */}
        <div className="form-group">
          <label>User Type</label>
          <Input
            type="select"
            value={formData.userType}
            onChange={(e) => handleInputChange("userType", e.target.value)}
          >
            <option value="">Select User Type</option>
            <option value="Vendor">Vendor</option>
            <option value="Customer">Customer</option>
            <option value="Driver">Driver</option>
            <option value="Admin">Admin</option>
          </Input>
        </div>
        <div className="form-group">
          <label>User Name</label>
          <Input
            type="text"
            value={formData.userName}
            onChange={(e) => handleInputChange("userName", e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Mobile Number</label>
          <Input
            type="text"
            value={formData.mobileNumber}
            onChange={(e) => handleInputChange("mobileNumber", e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Password</label>
          <Input
            type="password"
            value={formData.password}
            onChange={(e) => handleInputChange("password", e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>OTP</label>
          <Input
            type="text"
            value={formData.otp}
            onChange={(e) => handleInputChange("otp", e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Business Name</label>
          <Input
            type="text"
            value={formData.businessDetails.businessName}
            onChange={(e) =>
              handleInputChange("businessDetails.businessName", e.target.value)
            }
          />
        </div>
        <div className="form-group">
          <label>Shop Number</label>
          <Input
            type="text"
            value={formData.businessDetails.businessAddress.shopNumber}
            onChange={(e) =>
              handleInputChange(
                "businessDetails.businessAddress.shopNumber",
                e.target.value
              )
            }
          />
        </div>
        <div className="form-group">
          <label>Building Name</label>
          <Input
            type="text"
            value={formData.businessDetails.businessAddress.buildingName}
            onChange={(e) =>
              handleInputChange(
                "businessDetails.businessAddress.buildingName",
                e.target.value
              )
            }
          />
        </div>
        <div className="form-group">
          <label>POC Name</label>
          <Input
            type="text"
            value={formData.pocDetails.name}
            onChange={(e) =>
              handleInputChange("pocDetails.name", e.target.value)
            }
          />
        </div>
        <div className="form-group">
          <label>POC Mobile Number</label>
          <Input
            type="text"
            value={formData.pocDetails.mobileNumber}
            onChange={(e) =>
              handleInputChange("pocDetails.mobileNumber", e.target.value)
            }
          />
        </div>
        <div className="form-group">
          <label>Personal Documents</label>
          {/* Example to handle personal docs; you can implement a file upload if needed */}
          <Input
            type="text"
            placeholder="Document Type"
            onChange={(e) => {
              const newDoc = { docType: e.target.value, documentUrl: "" }; // Just an example
              setFormData((prevData) => ({
                ...prevData,
                personalDocs: [...prevData.personalDocs, newDoc],
              }));
            }}
          />
        </div>
        <div className="form-group">
          <label>Driver Name</label>
          <Input
            type="text"
            value={formData.driverDetails.driverName}
            onChange={(e) =>
              handleInputChange("driverDetails.driverName", e.target.value)
            }
          />
        </div>
        <div className="form-group">
          <label>Driver Photo URL</label>
          <Input
            type="text"
            value={formData.driverDetails.photo}
            onChange={(e) =>
              handleInputChange("driverDetails.photo", e.target.value)
            }
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={handleSubmit}>
          Save
        </Button>
        <Button color="secondary" onClick={toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};
