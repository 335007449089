import React, { useEffect, useState } from "react";
import { useAuth } from "../../context/AuthContext";
import { useUserContext } from "../../context/UserContext.jsx";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import * as Routes from "../../utils/routeNames";
import { BASE_URL } from "../../utils/routeNames.js";
import AddMobilesForProductDialog from "./addMobilesForProduct.js";
import toast from "react-hot-toast";
function Products() {
  const [products, setProducts] = useState([]);
  const [productDetails, setProductDetails] = useState([]);
  const [auth] = useAuth();

  // pagination and search
  const [pageNo, setPageNo] = useState(1); // pageNO is currentPage
  const [pageSize] = useState(32);
  const [totalProducts, setTotalProducts] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [showWithoutMobile, setShowWithoutMobile] = useState(false);
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const handleAddMobile = (productDetails) => {
    setProductDetails(productDetails);
    setShowModal(true); // Show the modal when "Add Mobile" is clicked
  };
  const getProduct = async (currentPage = pageNo) => {
    try {
      const res = await axios.get(
        `${BASE_URL}/product/getProducts?pageNo=${currentPage}&pageSize=${pageSize}&search=${searchQuery}`,
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        }
      );

      setProducts(res.data.products);
      setTotalProducts(res.data.totalProducts);
      setTotalPages(res.data.totalPages);
      // console.log("Products", res.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (auth?.token) {
      getProduct();
    }
  }, [auth, pageNo, searchQuery]);

  const handleView = (id) => {
    navigate(`${Routes.VIEW_PRODUCT}/${id}`);
  };

  const handleEdit = (id) => {
    navigate(`${Routes.UPADATE_PRODUCT}/${id}`, { replace: true });
  };

  const handleDelete = async (id) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this product?"
    );
    if (confirmDelete) {
      try {
        // Perform the DELETE request
        await axios.delete(`${BASE_URL}/product/${id}`, {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        });

        // Remove the deleted product from the products list locally
        setProducts((prevProducts) =>
          prevProducts.filter((product) => product._id !== id)
        );

        toast.success("Product deleted successfully.");
      } catch (error) {
        console.error("Error deleting product:", error);
        toast.error("Failed to delete product.");
      }
    }
  };

  const handleAddProduct = () => {
    navigate(Routes.NEW_PRODUCT);
  };

  const filteredProducts = products?.filter((product) =>
    product.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const indexOfLastProduct = pageNo * pageSize;
  const indexOfFirstProduct = indexOfLastProduct - pageSize;

  const handleNextPage = () => {
    if (pageNo < Math.ceil(totalProducts / pageSize)) {
      setPageNo(pageNo + 1);
    }
  };

  const handlePreviousPage = () => {
    if (pageNo > 1) {
      setPageNo(pageNo - 1);
    }
  };

  return (
    <div className="content-wrapper">
      <section className="content-header">
        <div className="container-fluid">
          <div className="row align-items-center justify-content-between my-3">
            <div className="col-md-4">
              <h1 className="text-left ">Product Catalog</h1>
            </div>
            <div className="col-md-8 d-flex justify-content-end">
              <div className="form-group mb-0 flex-grow-1 mr-3">
                <div className="input-group input-group-md">
                  <input
                    type="search"
                    className="form-control form-control-md"
                    placeholder="Search by Product Name"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                  <div className="input-group-append">
                    <button
                      className="btn btn-outline-secondary btn-md"
                      type="button"
                    >
                      <i className="fa fa-search" />
                    </button>
                  </div>
                </div>
              </div>
              <button
                onClick={handleAddProduct}
                className="btn btn-success ml-2"
              >
                <i className="fas fa-plus mr-1"></i> Add Product
              </button>
            </div>
          </div>
        </div>
      </section>

      <section className="content">
        <div className="container-fluid ">
          <div class="card card-solid p-2">
            <div>
              <div className="form-group">
                <div className="icheck-primary">
                  <input
                    type="checkbox"
                    id="showWithoutMobile"
                    checked={showWithoutMobile}
                    onChange={(e) => {
                      setShowWithoutMobile(e.target.checked);
                    }}
                  />
                  <label htmlFor="showWithoutMobile">Show Without Mobile</label>
                </div>
              </div>
              {/* Table View for Desktop */}
              <div className="table-responsive d-none d-md-block">
                <table className="table table-striped" border="1">
                  <thead className="table-dark">
                    <tr>
                      <th>Image</th>
                      <th>Product Name</th>
                      <th>Mobile Name(s)</th>
                      <th>Category</th>
                      <th>Price</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredProducts?.length > 0 ? (
                      filteredProducts.map((prod) => (
                        <tr key={prod._id}>
                          {showWithoutMobile && prod.mobile.length != 0 ? (
                            <div></div>
                          ) : (
                            // <td colSpan="6" className="empty-mobile-info"></td> // Empty td when showWithoutMobile is true
                            <>
                              <td>
                                <img
                                  src={`${BASE_URL.replace("/api", "")}${
                                    prod.imageUrl
                                  }`}
                                  alt={prod.name}
                                  style={{
                                    height: "50px",
                                    objectFit: "contain",
                                  }}
                                />
                              </td>
                              <td>{prod.name}</td>
                              <td>
                                {prod.mobile.map((mobile, index) => (
                                  <ul key={index}>
                                    <li>
                                      {mobile?.modelId?.brandId?.name || "N/A"}{" "}
                                      {mobile?.modelId?.name || "N/A"}
                                    </li>
                                  </ul>
                                ))}
                              </td>
                              <td>{prod?.category?.category_name || "N/A"}</td>
                              <td>
                                {prod.currency} {prod.salePrice}
                              </td>
                              <td>
                                {/* Add Mobile code */}
                                <button
                                  className="btn btn-primary btn-sm rounded-pill shadow-sm"
                                  onClick={() => handleAddMobile(prod)}
                                >
                                  <i className="fas fa-plus-circle"></i> Add
                                  Mobile
                                </button>

                                {/* Modal for Adding Mobiles*/}
                                <AddMobilesForProductDialog
                                  mobiles={prod.mobile}
                                  showModal={showModal}
                                  onClose={() => setShowModal(false)}
                                  // productId={prod._id}
                                  product={productDetails}
                                  // productName={prod.name}
                                  authToken={auth?.token}
                                  loadProductDetails={getProduct}
                                />
                                {/* Add Mobile code  Ends abv*/}
                                <button
                                  className="btn btn-sm btn-info mx-1"
                                  onClick={() => handleView(prod._id)}
                                >
                                  <i className="fas fa-eye"></i>
                                </button>
                                <button
                                  className="btn btn-sm btn-warning mx-1"
                                  onClick={() => handleEdit(prod._id)}
                                >
                                  <i className="fas fa-edit"></i>
                                </button>

                                <button
                                  className="btn btn-sm btn-danger mx-1"
                                  onClick={() => handleDelete(prod._id)}
                                >
                                  <i className="fas fa-trash"></i>
                                </button>
                              </td>{" "}
                            </>
                          )}
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="5" className="text-center">
                          No products found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>

              {/* Card View for Mobile */}
              <div className="row d-md-none">
                {filteredProducts?.length > 0 ? (
                  filteredProducts.map((prod) => (
                    <div
                      className="col-12 col-sm-6 col-md-3 mb-4"
                      key={prod._id}
                      onClick={() => handleView(prod)}
                    >
                      <div className="card card-outline card-dark shadow">
                        <div className="card-header p-0">
                          <img
                            src={`${BASE_URL.replace("/api", "")}${
                              prod.imageUrl
                            }`}
                            className="card-img-top rounded-top"
                            alt={prod.name}
                            style={{ height: "200px", objectFit: "contain" }}
                          />
                          <h5 className="card-title text-bold text-dark text-center m-2">
                            {prod.name}
                          </h5>
                        </div>
                        <div className="card-body">
                          <div className="mb-2 d-flex justify-content-between align-items-center">
                            <span className="text-muted">Category:</span>
                            <span className="text-dark">
                              {prod?.category?.category_name || "N/A"}
                            </span>
                          </div>
                          <div className="mb-2 d-flex justify-content-between align-items-center">
                            <span className="text-muted">Price:</span>
                            <span className="text-dark">
                              {prod.currency} {prod.salePrice}
                            </span>
                          </div>
                          <div className="d-flex justify-content-center">
                            <button
                              className="btn btn-sm btn-info"
                              onClick={() => handleView(prod)}
                            >
                              <i className="fas fa-eye"></i> View Details
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <p>No products found</p>
                )}
              </div>
            </div>

            <div className="card-footer d-flex align-items-center justify-content-between">
              {/* Pagination Controls */}
              <div className="d-flex align-items-center my-2">
                <button
                  className="btn btn-outline-primary"
                  onClick={handlePreviousPage}
                  disabled={pageNo === 1}
                >
                  <i className="fas fa-arrow-left mr-1"></i> Previous
                </button>

                <span className="mx-3 text-bold">
                  {pageNo} / {totalPages}
                </span>

                <button
                  className="btn btn-outline-primary"
                  onClick={handleNextPage}
                  disabled={pageNo === totalPages}
                >
                  Next <i className="fas fa-arrow-right ml-1"></i>
                </button>
              </div>

              {/* Total Products */}
              <div className="ml-auto">
                <span className="mx-3 text-bold">{totalProducts}</span>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Products;
