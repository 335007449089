import React, { useState, useEffect } from "react";
import axios from "axios";
import { BASE_URL } from "../../utils/routeNames";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { useAuth } from "../../context/AuthContext";
const ViewCoupon = () => {
  const { id } = useParams();
  const [coupon, setCoupon] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [auth] = useAuth();

  useEffect(() => {
    const fetchCouponDetails = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/coupon/${id}`, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${auth?.token}`,
          },
        });
        setCoupon(response.data);
        setLoading(false);
      } catch (err) {
        setError("Failed to fetch coupon details.");
        setLoading(false);
      }
    };

    fetchCouponDetails();
  }, [id]);

  if (loading) {
    return (
      <div className="container text-center mt-5">
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="container text-center mt-5">
        <div className="alert alert-danger">{error}</div>
      </div>
    );
  }

  return (
    <div className="content-wrapper">
      <div className="container mt-5">
        <h2 className="mb-4">Coupon Details</h2>
        {coupon && (
          <div className="card">
            <div className="card-header bg-primary text-white">
              <h4 className="card-title">Coupon Information</h4>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  <p>
                    <strong>Code:</strong> {coupon.code}
                  </p>
                  <p>
                    <strong>Description:</strong> {coupon.description || "N/A"}
                  </p>
                  <p>
                    <strong>Discount Type:</strong> {coupon.discountType}
                  </p>
                  <p>
                    <strong>Discount Value:</strong> {coupon.discountValue}
                  </p>
                </div>
                <div className="col-md-6">
                  <p>
                    <strong>Min Order Amount:</strong> {coupon.minOrderAmount}
                  </p>
                  <p>
                    <strong>Start Date:</strong>{" "}
                    {new Date(coupon.startDate).toLocaleDateString()}
                  </p>
                  <p>
                    <strong>End Date:</strong>{" "}
                    {new Date(coupon.endDate).toLocaleDateString()}
                  </p>
                  <p>
                    <strong>Active Status:</strong>{" "}
                    {coupon.isActive ? "Active" : "Inactive"}
                  </p>
                </div>
              </div>
            </div>
            <div className="card-footer text-center">
              <button
                className="btn btn-success btn-sm"
                onClick={() => navigate(-1)}
              >
                Back to Coupons List
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ViewCoupon;
